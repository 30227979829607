import plant from "../assets/Image-6.webp";
import fca from "../assets/Screenshot 2021-10-06 at 12.21.47.webp";
import biba from "../assets/british-insurance-brokers-association-biba-logo-37B1CD0DD6-seeklogo.com.webp";

// import alloy from "../assets/alloy2x.webp";

// import tyre from "../assets/tyre2x.webp";
// import gap from "../assets/gap2x.webp";
import relaxing from "../assets/undraw_Relaxing_at_home_re_mror.svg";
import rating from "../assets/d4kmbbud@2x.webp";

import gapHeader from "../assets/gap.webp";
import tyreHeader from "../assets/tyre.webp";
import cosmetic from "../assets/cosmetic.webp";
import alloyHeader from "../assets/alloy.webp";

import car_ins_homepage from "../assets/car_ins_homepage.webp";
import car_ins_par_homepage from "../assets/car_ins_par_homepage.webp";

import tyreInsurance2 from "../assets/tyre_insurance_2.webp";
import cosmeticInsurance3 from "../assets/cosmetic_insurance_3.webp";
import excessInsurance3 from "../assets/excess_insurance_3.webp";
import gapInsurance3 from "../assets/GAP_insurance_3.webp";
import vehicleWarranty3 from "../assets/vehicle_warranty_3.webp";
import vehicleWarranty4 from "../assets/vehicle_warranty_4.webp";
import weddingInsurance3 from "../assets/wedding_insurance_3.webp";

//checkout cards first face image
import alloyInsurance from "../assets/alloy_insurance.webp";
import gapInsurance2 from "../assets/gap_insurance.webp";
import tyreInsurance from "../assets/tyre_insurance.webp";
import cosmeticInsurance from "../assets/cosmetic_repair.webp";
import mbi from "../assets/mbi.webp";


// SMH headers

import imgAlloySMH from "../assets/imgAlloy.webp";
import imgAlloypngSMH from "../assets/imgAlloypng.png";
import imgTyreSMH from "../assets/imgTyre.webp";
import imgTyrepngSMH from "../assets/imgTyrepng.png";
import imgCosmeticSMH from "../assets/imgCosmetic.webp";
import imgCosmeticpngSMH from "../assets/imgCosmeticpng.png";
import imgWarrantySMH from "../assets/imgWarranty.webp";
import imgWarrantypngSMH from "../assets/imgWarrantypng.png";
import imgGAPSMH from "../assets/imgGAP.webp";
import imgGAPpngSMH from "../assets/imgGAPpng.png";
import imgExcessSMH from "../assets/imgExcess.webp";
import imgExcesspngSMH from "../assets/imgExcesspng.png";
import imgWeddingSMH from "../assets/imgWedding.webp";
import imgWeddingpngSMH from "../assets/imgWeddingpng.png";
import imgAboutUsSMH from "../assets/imgAboutUs.webp";
import imgAboutUspngSMH from "../assets/imgAboutUspng.png";
import imgTreeSMH from "../assets/imgTree.webp";
import imgTreepngSMH from "../assets/imgTreepng.png";
import imgHomeSMH from "../assets/imgHome.webp";
import imgHomepngSMH from "../assets/imgHomepng.png";


// Invictus header

import imgAlloyInv from "../assets/inv/imgAlloy.webp";
import imgAlloypngInv from "../assets/inv/imgAlloypng.png";
import imgTyreInv from "../assets/inv/imgTyre.webp";
import imgTyrepngInv from "../assets/inv/imgTyrepng.png";
import imgCosmeticInv from "../assets/inv/imgCosmetic.webp";
import imgCosmeticpngInv from "../assets/inv/imgCosmeticpng.png";
import imgWarrantyInv from "../assets/inv/imgWarranty.webp";
import imgWarrantypngInv from "../assets/inv/imgWarrantypng.png";
import imgGAPInv from "../assets/inv/imgGAP.webp";
import imgGAPpngInv from "../assets/inv/imgGAPpng.png";
import imgExcessInv from "../assets/inv/imgExcess.webp";
import imgExcesspngInv from "../assets/inv/imgExcesspng.png";
import imgWeddingInv from "../assets/inv/imgWedding.webp";
import imgWeddingpngInv from "../assets/inv/imgWeddingpng.png";
import imgAboutUsInv from "../assets/inv/imgAboutUs.webp";
import imgAboutUspngInv from "../assets/inv/imgAboutUspng.png";
import imgTreeInv from "../assets/inv/imgTree.webp";
import imgTreepngInv from "../assets/inv/imgTreepng.png";
import imgHomeInv from "../assets/inv/imgHome.webp";
import imgHomepngInv from "../assets/inv/imgHomepng.png";


import weddingBackgroundPng from "../assets/wedding_bk_card.jpeg";
import weddingBackgroundWebp from "../assets/wedding_background.webp";


import giving from "../assets/about-us-giving-back.webp";
import aboutUsExperience from "../assets/about-us-experience.webp";
import aboutUs2 from "../assets/about-us-2.webp"

import newImg from "../assets/car_insurance_products.jpg";
import newImg2 from "../assets/vehicle_warranty_4.jpeg";
import newImgwebp from "../assets/car_insurance_products.webp";
import newImg2Webp from "../assets/vehicle_warranty_4.webp";



/// resized image
import alloy26 from "../assets/alloy26.webp";
import gap26 from "../assets/gap26.webp";
import tyre26 from "../assets/tyre26.webp";
import cosmetic26 from "../assets/scratch26.webp";
import mbi26 from "../assets/excess26.webp";

//tree plated page assets
import forestHeader from "../assets/Forest---Header---1.jpg";
import importanceOfTrees from "../assets/pledge_trees.webp";
import air from "../assets/pledge_air.webp";
import water from "../assets/pledge_water.webp";
import biodiversity from "../assets/pledge_biodiversity.webp";
import socialImpact from "../assets/pledge_socialimpact.webp";
import health from "../assets/pledge_health.webp";
import climate from "../assets/pledge_climate.webp";



import alloy_paragraph_image from "../assets/alloy_paragraph_image.webp";
import excess_insurance_paragraph from "../assets/excess_insurance_paragraph.jpeg";
import gap_insurance_paragraph from "../assets/gap_insurance_paragraph.webp";
import warranty_insurance_paragraph from "../assets/warranty_insurance_paragraph.webp";
import cosmetic_paragraph_image from "../assets/cosmetic_paragraph_image.webp";
import wedding_insurance_paragraph from "../assets/wedding_insurance_paragraph.webp"
import treeLeft from "../assets/treeLeft.png"

import logo_smh from "../assets/logo.webp"
import logo_smh_white from "../assets/smh-white.webp"
import logo_footer_white from "../assets/smh_logo_white.png"

import logo_invictus from "../assets/logo-invictus.webp"
import logo_invictus_white from "../assets/logo-invictus-white.webp"

import logo_aib_white from "../assets/aibWhite.webp"
import logo_aib from "../assets/logo-aib.webp"
import logo_aib_footer from "../assets/aibRedWhite.webp"

import productsArray from "./products.json";


// export const MyTheme = {
//   pageTitle: "SaveMoneyHub",
//   footerDescription: "SaveMoneyHub.com is a trading style",
//   pageUrl: "savemoneyhub.com",
//   email: "hello@savemoneyhub.com",
//   phone: "01615051420",
//   displayPhone: "0161 505 1420",
//   externalLogo: "https://portal.savemoneyhub.com/Content/themes/savemoneyhub/logo.png",
//   twitterUrl: "https://twitter.com/savemoneyhubuk",
//   cookie: "savemoneyhubCookie",
//   logo: logo_smh,
//   logo_white: logo_smh_white,
//   logo_footer: logo_footer_white,
//   color: "#ED74AA",
//   imgwebp: imgHome,
//   imgpng: imgHomepng,
//   review: true,
// }

// export const MyTheme = {
//   pageTitle: "Invictus Protect Limited",
//   footerDescription: "Invictus Protect Limited is an Appointed Representative",
//   pageUrl: "invictusprotect.com/",
//   email: "info@invictusprotect.com",
//   phone: "01615051404",
//   displayPhone: "0161 505 1404",
//   externalLogo:"",
//   twitterUrl: "",
//   cookie: "invictusprotectCookie",
//   logo: logo_invictus,
//   logo_white: logo_invictus_white,
//   logo_footer: logo_invictus_white,
//   color: "#253551",
//   imgwebp: imgHome,
//   imgpng: imgHomepng,
// }

const productsOrigin = new Set(productsArray?.map((item) => item.Origin))
export const originExist = (...origins) => origins.every(Origin => productsOrigin.has(Origin))


export function weddingActivate() {
  let theme = process.env.GATSBY_THEME_SETTINGS
  let wedding = process.env.GATSBY_THEME_WEDDING
  let activate = false;

  if (theme == 'smh' && wedding == 'true') {
    activate = true;
  } else {
    activate = false;
  }
  return activate;
}


export function checkTheme() {
  let theme = process.env.GATSBY_THEME_SETTINGS

  if (theme == 'smh') {
    let MyTheme = {
      pageTitle: "SaveMoneyHub",
      footerDescription: "SaveMoneyHub.com is a trading style of Rest Ashored Ltd",
      fca: '732530',
      company: 'Rest Ashored Ltd',
      companyNo: '08179370',
      addressLine1: 'Suite B, V12 Merlin Park',
      addressLine2: 'Burscough, Lancashire',
      addressLine3: 'L40 8JY',
      pageUrl: "savemoneyhub.com",
      email: "hello@savemoneyhub.com",
      phone: "01615051420",
      displayPhone: "0161 505 1420",
      externalLogo: "https://portal.savemoneyhub.com/Content/themes/savemoneyhub/logo.png",
      twitterUrl: "https://twitter.com/savemoneyhubuk",
      cookie: "savemoneyhubCookie",
      displayLogos: true,
      logo: logo_smh,
      logo_white: logo_smh_white,
      logo_footer: logo_footer_white,
      color: "#ED74AA",
      imgwebp: imgHomeSMH,
      imgpng: imgHomepngSMH,
      review: true,
      imgAlloy: imgAlloySMH,
      imgAlloypng: imgAlloypngSMH,
      imgTyre: imgTyreSMH,
      imgTyrepng: imgTyrepngSMH,
      imgCosmetic: imgCosmeticSMH,
      imgCosmeticpng: imgCosmeticpngSMH,
      imgWarranty: imgWarrantySMH,
      imgWarrantypng: imgWarrantypngSMH,
      imgGAP: imgGAPSMH,
      imgGAPpng: imgGAPpngSMH,
      imgExcess: imgExcessSMH,
      imgExcesspng: imgExcesspngSMH,
      imgWedding: imgWeddingSMH,
      imgWeddingpng: imgWeddingpngSMH,
      imgAboutUs: imgAboutUsSMH,
      imgAboutUspng: imgAboutUspngSMH,
      imgTree: imgTreeSMH,
      imgTreepng: imgTreepngSMH,
      imgHome: imgHomeSMH,
      imgHomepng: imgHomepngSMH
    }
    return MyTheme;
  }

  else if (theme == 'invictus') {
    let MyTheme = {
      pageTitle: "Invictus Protect Limited",
      footerDescription: "Invictus Protect Limited is an Appointed Representative of Rest Ashored Ltd",
      fca: '732530',
      company: 'Rest Ashored Ltd',
      companyNo: '08179370',
      addressLine1: 'Suite B, V12 Merlin Park',
      addressLine2: 'Burscough, Lancashire',
      addressLine3: 'L40 8JY',
      pageUrl: "invictusprotect.com/",
      email: "info@invictusprotect.com",
      phone: "01615051404",
      displayPhone: "0161 505 1404",
      externalLogo: "",
      twitterUrl: "",
      cookie: "invictusprotectCookie",
      logo: logo_invictus,
      logo_white: logo_invictus_white,
      logo_footer: logo_invictus_white,
      color: "#253551",
      imgwebp: imgHomeInv,
      imgpng: imgHomepngInv,
      imgAlloy: imgAlloyInv,
      imgAlloypng: imgAlloypngInv,
      imgTyre: imgTyreInv,
      imgTyrepng: imgTyrepngInv,
      imgCosmetic: imgCosmeticInv,
      imgCosmeticpng: imgCosmeticpngInv,
      imgWarranty: imgWarrantyInv,
      imgWarrantypng: imgWarrantypngInv,
      imgGAP: imgGAPInv,
      imgGAPpng: imgGAPpngInv,
      imgExcess: imgExcessInv,
      imgExcesspng: imgExcesspngInv,
      imgWedding: imgWeddingInv,
      imgWeddingpng: imgWeddingpngInv,
      imgAboutUs: imgAboutUsInv,
      imgAboutUspng: imgAboutUspngInv,
      imgTree: imgTreeInv,
      imgTreepng: imgTreepngInv,
      imgHome: imgHomeInv,
      imgHomepng: imgHomepngInv
    }
    return MyTheme;
  }
  else {
    let MyTheme = {
      pageTitle: "Alternative Insurance Brokers",
      footerDescription: "Alternative Insurance Brokers is a trading name of Alternative Propositions Limited",
      fca: '602443',
      company: 'Alternative Propositions Limited',
      companyNo: '8253536',
      addressLine1: 'St Cristopher House, 217',
      addressLine2: 'Wellington Road, Stockport,',
      addressLine3: 'SK2 6NG',
      pageUrl: "alternativeinsurancebrokers.com",
      email: "enquiries@theaibgroup.co.uk ",
      phone: "01613882520",
      displayPhone: "0161 388 2520",
      externalLogo: "",
      twitterUrl: "",
      cookie: "aibCookie",
      logo: logo_aib,
      logo_white: logo_aib_white,
      logo_footer: logo_aib_footer,
      color: "#c1145f",
      // imgwebp: imgHomeSMH,
      // imgpng: imgHomepngSMH,
      // imgAlloy: imgAlloySMH,
      // imgAlloypng: imgAlloypngSMH,
      // imgTyre: imgTyreSMH,
      // imgTyrepng: imgTyrepngSMH,
      // imgCosmetic: imgCosmeticSMH,
      // imgCosmeticpng: imgCosmeticpngSMH,
      // imgWarranty: imgWarrantySMH,
      // imgWarrantypng: imgWarrantypngSMH,
      // imgGAP: imgGAPSMH,
      // imgGAPpng: imgGAPpngSMH,
      // imgExcess: imgExcessSMH,
      // imgExcesspng: imgExcesspngSMH,
      // imgWedding: imgWeddingSMH,
      // imgWeddingpng: imgWeddingpngSMH,
      // imgAboutUs: imgAboutUsSMH,
      // imgAboutUspng: imgAboutUspngSMH,
      // imgTree: imgTreeSMH,
      // imgTreepng: imgTreepngSMH,
      // imgHome: imgHomeSMH,
      // imgHomepng: imgHomepngSMH
    }
    return MyTheme;
  }
  
}


export const tableInformations = {

  thead: [
    {
      title: "SILVER",
      subtitle: "Includes £6,000 cancellation cover",
    },
    {
      title: "GOLD",
      subtitle: "Includes £10,000 cancellation cover",
    },
    {
      title: "AMBER",
      subtitle: "Includes £15,000 cancellation cover",
    },
    {
      title: "PLATINUM",
      subtitle: "Includes £20,000 cancellation cover",
    },
    {
      title: "DIAMOND",
      subtitle: "Includes £30,000 cancellation cover",
    },
    {
      title: "DIAMOND PLUS",
      subtitle: "Includes £50,000 cancellation cover",
    }

  ],
  rows: [
    {
      row: [
        {
          title: " ",
          subtitle: "",
        },
        {
          title: "",
          subtitle: "Buy Now",
        },
        {
          title: "",
          subtitle: "Buy Now",
        },
        {
          title: "",
          subtitle: "Buy Now",
        },
        {
          title: "",
          subtitle: "Buy Now",
        },
        {
          title: "",
          subtitle: "Buy Now",
        },
        {
          title: "",
          subtitle: "Buy Now",
        }
      ]

    },
    {
      row: [
        {
          title: "Cancellation, Curtailment & Rearrangement*",
        },
        {
          title: "£4,500",
        },
        {
          title: "£7,500",
        },
        {
          title: "£11,250",
        },
        {
          title: "£15,000",
        },
        {
          title: "£22,500",
        },
        {
          title: "£37,500",
        }
      ]

    },
    {
      row: [
        {
          title: "Ceremonial Attire",
        },
        {
          title: "£3,000",
        },
        {
          title: "£5,000",
        },
        {
          title: "£6,000",
        },
        {
          title: "£7,000",
        },
        {
          title: "£10,000",
        },
        {
          title: "£12,500",
        }
      ]
    },
    {
      row: [
        {
          title: "Wedding gifts",
        },
        {
          title: "£3,000 (£300)",
        },
        {
          title: "£5,000 (500)",
        },
        {
          title: "£6,000 (£600)",
        },
        {
          title: "£7,000 (£700)",
        },
        {
          title: "£10,000 (£1,000)",
        },
        {
          title: "£12,500 (£1,250)",
        }
      ]
    },
    {
      row: [
        {
          title: "Rings, flowers, attendants gifts & the wedding cake",
        },
        {
          title: "£3,000 (£500)",
        },
        {
          title: "£5,000 (£1,000)",
        },
        {
          title: "£6,000 (£1,250)",
        },
        {
          title: "£7,000 (£1,500)",
        },
        {
          title: "£10,000 (£2,000)",
        },
        {
          title: "£12,500 (£2,250)",
        }
      ]
    },
    {
      row: [
        {
          title: "Cars & transport",
        },
        {
          title: "£3,000",
        },
        {
          title: "£5,000",
        },
        {
          title: "£6,000",
        },
        {
          title: "£7,000",
        },
        {
          title: "£10,000",
        },
        {
          title: "£12,500",
        }
      ]
    },
    {
      row: [
        {
          title: "Photography & video",
        },
        {
          title: "£3,000",
        },
        {
          title: "£5,000",
        },
        {
          title: "£6,000",
        },
        {
          title: "£7,000",
        },
        {
          title: "£10,000",
        },
        {
          title: "£12,500",
        }
      ]
    },
    {
      row: [
        {
          title: "Financial failure of wedding service suppliers",
        },
        {
          title: "£3,000",
        },
        {
          title: "£5,000",
        },
        {
          title: "£7,000",
        },
        {
          title: "£10,000",
        },
        {
          title: "£15,000",
        },
        {
          title: "£17,500",
        }
      ]
    },
    {
      row: [
        {
          title: "Personal accident - loss of sight, limb(s) or permanent total disablement",
        },
        {
          title: "£5,000 (£1,000)",
        },
        {
          title: "£10,000 (£1,000)",
        },
        {
          title: "£12,500 (£1,000)",
        },
        {
          title: "£15,000 (£1,000)",
        },
        {
          title: "£10,000 (£1,000)",
        },
        {
          title: "£22,500 (£1,000)",
        }
      ]
    },
    {
      row: [
        {
          title: "Professional counselling",
        },
        {
          title: "£1,000",
        },
        {
          title: "£1,000",
        },
        {
          title: "£1,000",
        },
        {
          title: "£1,000",
        },
        {
          title: "£1,000",
        },
        {
          title: "£1,000",
        }
      ]
    },
    {
      row: [
        {
          title: "Legal expenses",
        },
        {
          title: "£3,000",
        },
        {
          title: "£5,000",
        },
        {
          title: "£7,500",
        },
        {
          title: "£10,000",
        },
        {
          title: "£12,500",
        },
        {
          title: "£15,000",
        }
      ]
    },
    {
      row: [
        {
          title: "Public liability for the marrying couple",
        },
        {
          title: "£2,000,000 (Excess: £250)",
        },
        {
          title: "£2,000,000 (Excess: £250)",
        },
        {
          title: "£2,000,000 (Excess: £250)",
        },
        {
          title: "£2,000,000 (Excess: £250)",
        },
        {
          title: "£2,000,000 (Excess: £250)",
        },
        {
          title: "£2,000,000 (Excess: £250)",
        }
      ]
    },


  ]
}



export const faqInformations = {

  "MBI": [
    {
      title: "Frequently asked questions",
      //description: "Tie the knot with "+checkTheme().pageTitle+" to gift yourself ultimate peace of mind for your truly special occasion.\n We vow to keep your investment safe and carry you over the financial threshold in the event of any unwanted interruptions to your big day.",
      label: "Purchase Cover →",
      href: "/checkout",
      questions: [
        {
          question: "What is the maximum age that my vehicle can be to qualify for a " + checkTheme().pageTitle + " warranty?",
          answers: [
            {

              answer: "If your vehicle is a car or light commercial vehicle, then it must be no more than 120 months old (from date of DVLA original registration) and not have covered more than 100,000 miles at the time of policy purchase."
            },
            {
              answer: "If your vehicle is a motorcycle, then it must be no more than 96 months old (from date of DVLA original registration) and not have covered more than 70,000 miles at the time of policy purchase "
            },
            {
              answer: "If your vehicle is a motorhome, then it must be no more than 60 months old (from date of DVLA original registration) and not have covered more than 60,000 miles at the time of policy purchase."
            }

          ]

        },
        {
          question: "How long will my " + checkTheme().pageTitle + " vehicle warranty last?",
          answers: [
            {
              answer: "You can buy an extended warranty for periods of 12, 24 or 36 months.",
            }
          ]
        },
        {
          question: "Can I spread the costs of the " + checkTheme().pageTitle + " vehicle warranty?",
          answers: [
            {
              answer: "You can. " + checkTheme().pageTitle + " will allow you to spread the costs of your warranty over 12 equal monthly payments.",
            }
          ]
        },
        {
          question: "If my vehicle breaks down on holiday, how do I claim?",
          answers: [
            {
              answer: "Please do not worry, our claims process is the same as if you were in the UK. The only difference being that you will have to pay for any costs, directly to the repairer. On your return, you will then reclaim our authorised and agreed claim amount from us.",
            }
          ]
        },
        {
          question: "Can I make a claim out of hours?",
          answers: [
            {
              answer: "Yes. You can register any warranty claim with us on our “Out of hours” message service but we cannot approve any potential claims until we re-open. Please ensure that you DO NOT authorise the repairer to start any work on your vehicle until we have agreed the claim.",
            }
          ]
        },
        {
          question: "What happens if I sell my vehicle?",
          answers: [
            {
              answer: "If you decide to sell your vehicle before your " + checkTheme().pageTitle + " warranty has expired, you can request that we transfer any remaining period to the new owner. There would be an administration fee payable, should we agree to the transfer.",
            }
          ]
        },
        {
          question: "Do I need to pay any excess?",
          answers: [
            {
              answer: "No. There is no policy excess.",
            }
          ]
        },
        {
          question: "Is there any additional cost for the make or model of vehicle, or if I have a 4×4 vehicle?",
          answers: [
            {
              answer: "No. We only rate our warranty prices based on if the engine size and it`s age, so you will not be charged more just because you own a prestige make of vehicle.",
            }
          ]
        },
        {
          question: "What does Boost cover include?",
          answers: [
            {
              answer: "Our Boost cover includes the Battery, Catalytic Convertor, Satellite Navigation system, In-car Entertainment and Diesel Particulate Filter.",
            }
          ]
        },
        {
          question: "Do I need to pay for my repairs?",
          answers: [
            {
              answer: "No. If you use one of our approved repairers, we will pay them on your behalf meaning that you do not need to pay and then reclaim from us.",
            }
          ]
        },
        {
          question: "Can I buy your warranty now and start it when my manufacturer warranty expires?",
          answers: [
            {
              answer: "Yes. Your " + checkTheme().pageTitle + " warranty will start from the date commencing immediately after expiry of the manufacturers’ existing warranty period.",
            }
          ]
        },
        {
          question: "Can I transfer my warranty policy?",
          answers: [
            {
              answer: "Yes. You have two options available if you sell your vehicle."
            },
            {
              answer: "If you have sold your vehicle to a private buyer, you can transfer the unused portion of your warranty to the new owners - subject to our approval, and a payment of an administration fee. We cannot transfer the policy if you sell your vehicle to a motor dealer."
            },
            {
              answer: "Alternatively, if you have purchased a replacement vehicle, the unused warranty term can be transferred to the replacement vehicle. This can be done with us by phone or email.",
            }
          ]
        },


      ]
    }
  ],
  "Wedding": [
    {
      title: "Frequently asked questions",
      description: "Tie the knot with " + checkTheme().pageTitle + " to gift yourself ultimate peace of mind for your truly special occasion.\n We vow to keep your investment safe and carry you over the financial threshold in the event of any unwanted interruptions to your big day.",
      label: "Purchase Cover →",
      href: "/wedding-checkout",
      questions: [
        {
          question: "Is wedding insurance paid monthly?",
          answers: [
            {
              answer: "No, wedding insurance is just a one-off payment, showing what excellent value for money it is.",
            }
          ]
        },
        {
          question: "When should we take out wedding insurance?",
          answers: [
            {
              answer: "Ideally, you should take it out as soon as you have paid any deposits or signed a contract that means you will lose money if you are forced to change your plans. You must purchase this more than 14 days in advance of the wedding date.",
            }
          ]
        },
        {
          question: "Who can buy wedding insurance?",
          answers: [
            {
              answer: "Anyone can buy a wedding insurance policy, for instance parents of the bride or groom, but the policy must be in the name of the two people who are getting married as it is their wedding being covered.",
            }
          ]
        },
        {
          question: "I have already paid a deposit. Will this be covered?",
          answers: [
            {
              answer: "Yes - provided you are not aware of any particular circumstances that may give rise to a claim when you take out the policy.",
            }
          ]
        },
        {
          question: "Does wedding insurance cover relative's money?",
          answers: [
            {
              answer: `Our wedding insurance will cover money paid by relatives of the couple, providing they have a demonstrable financial interest, such as a receipt or booking confirmation. \n
        
              All claims will have to be submitted by the insured couple and then the settlement passed on to the person who paid.`,
            }
          ]
        },
        {
          question: "My fiancé lives abroad. Can we take out a policy?",
          answers: [
            {
              answer: "Yes - provided one of you is normally resident here in the UK you can take out a policy.",
            }
          ]
        },
        {
          question: "My fiancé is in the Armed Forces. Are we covered if he is unexpectedly posted abroad?",
          answers: [
            {
              answer: "Yes - provided that there was no notice of possible cancellation of leave at the time the policy was taken out.",
            }
          ]
        },
        {
          question: "What about if one of us decides not to go ahead with the marriage after all?",
          answers: [
            {
              answer: `This is known as "disinclination to proceed" and is not an insured reason for cancellation, sadly. However, you may be able to claim for cost of professional counselling under Section 9 of the policy.`,
            }
          ]
        },
        {
          question: "Is family bereavement covered by wedding insurance?",
          answers: [
            {
              answer: "Cancellation or rearrangement due to family bereavement is covered under section 1 of our wedding insurance policy if it makes going ahead with the wedding inappropriate. However, you must answer the medical declaration for the policy in respect of all people on whom your wedding plans depend before you purchase the policy. If you answer yes to any of the medical questions with regard to a relative, you will not be covered in relation to that medical condition. You will of course be covered for anything unrelated to that relative's condition.If you are in doubt please contact us on 0161 505 1420, before you purchase the policy.",
            }
          ]
        },
        {
          question: "What happens if the professional wedding photographs fail because the film or digital storage media is faulty and there are no copies available?",
          answers: [
            {
              answer: "Then the reasonable costs of taking replacement formal photographs of the couple and the immediate Wedding party can be claimed.",
            }
          ]
        },
        {
          question: "I have an existing medical condition. Can this be covered?",
          answers: [
            {
              answer: "Most existing medical conditions are insured automatically, both for the Couple and also their close relatives. There is no cover, however, for Pre-existing medical conditions that have resulted in a terminal prognosis or where the condition is awaiting treatment or investigation at a hospital or you are awaiting the results of tests or investigations, unless you have declared details to us and we have agreed to insure them. You can call us to discuss a medical condition on 0161 505 1420.",
            }
          ]
        },
        {
          question: "What happens if one of the suppliers goes bust?",
          answers: [
            {
              answer: "Then you may claim for any irrecoverable deposits that you may have paid and also for the necessary additional costs in arranging an equivalent alternative. A definition of wedding service suppliers is included in the policy wording.",
            }
          ]
        },
        {
          question: "Our wedding venue is flooded just before the big day. Are we covered?",
          answers: [
            {
              answer: "Then you may claim for any irrecoverable deposits that you may have paid and also for the necessary additional costs in arranging an equivalent alternative. A definition of wedding service suppliers is included in the policy wording.",
            }
          ]
        },
        {
          question: "Our venue requires us to provide insurance in case any of us or the guests causes accidental damage during the reception. Is this included?",
          answers: [
            {
              answer: "The marrying couple’s Public liability is included as standard up to £2m. There is an optional extension available to add cover for the liabilities of your wedding party up to £2m in total. This can be further increased to be £5m with the additional extension.",
            }
          ]
        },
        {
          question: "Is my honeymoon or overseas wedding trip insured on this policy?",
          answers: [
            {
              answer: "No - you will need to take out separate travel insurance for your honeymoon or overseas wedding trip, which would normally include cancellation cover if you are not able to travel as planned.",
            }
          ]
        },
        {
          question: "Does the wedding insurance policy have an excess?",
          answers: [
            {
              answer: "Yes, there is an excess of £100 applicable under all sections of the policy, except for the Public Liability sections (sections 12, 13 & 14) which have an excess of £250.",
            }
          ]
        },
      ],
    },
  ]
}

export const paragraphImage = {

  "Alloy Wheel": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "Pristine alloy wheels without the cost of repair",
      description: "Scraped the curb? It’s okay. We’ve got you. With this Alloy Wheel product, you’re covered for up to £200 per claim, and up to £2,000 in Aggregate.",
      label: "What's covered →",
      tableTitle: "A range of cover options",
      tableDescription: [
        {
          title: '18" & BELOW',
          term: "12, 24, 36 Months",
          claim_limit: "£100",
          ppid: "23",
        },
        {
          title: 'OVER 18"',
          term: "12, 24, 36 Months",
          claim_limit: "£200",
          ppid: "23",
        },
        {
          title: 'ANY DIAMOND CUT & POLISHED',
          term: "12, 24, 36 Months",
          claim_limit: "£200",
          ppid: "23",
        }
      ],
    }


  ],
  "Wedding": [
    {
      // add key "image" to add  image insetead of table: e.g. "image": cosmetic_insurance
      title: "Don't leave anything to chance",
      description: "Weddings and civil ceremonies are a special cause for celebration; a day you will remember for the rest of your life. We know they can cost a lot, both financially and emotionally. We also understand how stressful it can be if something doesn’t quite go how you planned it. That’s why at " + checkTheme().pageTitle + ", we don’t leave anything to chance, offering Wedding Insurance to give you the peace of mind on your big day.",
      label: "What's Covered →",
      image: wedding_insurance_paragraph
    }
  ],
  "Tyre": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "Cover all replacement tyre costs",
      description: "Don’t let a puncture stop you in your tracks. Whether you’ve driven over a nail, or made a new enemy (don’t worry, we’re not one to pry!), our policy covers you for both accidental and malicious damage.",
      label: "What's covered →",
      tableTitle: "A range of cover options",
      tableDescription: [
        {
          title: '18" & BELOW',
          term: "12, 24, 36 Months",
          claim_limit: "£250",
          ppid: "23",
        },
        {
          title: 'OVER 18"',
          term: "12, 24, 36 Months",
          claim_limit: "£250",
          ppid: "23",
        },
        {
          title: 'RUN FLAT',
          term: "12, 24, 36 Months",
          claim_limit: "£250",
          ppid: "23",
        }
      ],
    }


  ],
  "Cosmetic Repair": [
    {
      // add key "image" to add  image insetead of table: e.g. "image": cosmetic_insurance
      title: "Great Value Cosmetic/SMART Repair",
      description: "Drive the car you love without the hassle of damage costs thanks to " + checkTheme().pageTitle + "’s cosmetic repair insurance, providing cover for minor dents, stone chips, scratches and scuffs.",
      label: "What's covered →",
      image: cosmetic_paragraph_image
    }


  ],
  "Excess": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "Say no to excess - cover all eventualities",
      description: "Cover yourself in all situations with " + checkTheme().pageTitle + "’s excess car insurance, designed to keep unnecessary bills away in the event of making a claim.",
      label: "What's covered →",
      image: excess_insurance_paragraph
    }


  ],
  "Finance Gap": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "Don’t get caught short",
      description: "When we buy our vehicles, the last thing we ever imagine is the possibility it may be written off. That’s why many vehicle owners are left out of pocket when the unthinkable happens, as all insurers are only legally obliged to compensate the current market value of your vehicle. GAP Insurance from " + checkTheme().pageTitle + " provides protection against financial loss that may incur as a result of your vehicle being a total write-off due to accident, fire, vandalism, flood or theft.",
      label: "What's covered →",
      image: gap_insurance_paragraph
    }

  ],
  "MBI": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "It’s time to eliminate expensive bills",
      description: "With " + checkTheme().pageTitle + ", you can rest easy, even if your car lets you down. Because we can cover up to 100% of your breakdown repair costs, so you don’t get caught with an unexpected bill. Mechanical and electrical failures can happen, and do happen. So get covered, and let " + checkTheme().pageTitle + " cushion any unforeseen blows with secure and reliable breakdown insurance.",
      label: "What's covered →",
      image: warranty_insurance_paragraph
    }

  ],

}

export const aboutUsPageInfo = [

  {
    title: `Decades of Experience `,
    description: `Just think how good you’d be at something if you’d been doing it for over 20 years? 
Our team of car-lovers have been saving people money and time for decades, using 
their experience and knowledge across all kinds of insurance markets in the UK and 
across Europe. 
That’s why we know how to get the best deal for you and your vehicle.
We’ve used all that knowledge to create a helpful range of policies to keep you fully 
covered.`,
    image: aboutUsExperience,
  },
  {
    title: `We Believe in Giving Back`,
    description: `Responsibility is an important word to us here at ` + checkTheme().pageTitle + `. 
Not only are we responsible for your car’s every need, but we do our part in being 
responsible for nurturing our planet. 
That’s why for every policy sold, we plant one tree. 
If you think about it, that’s A LOT of trees. 
Especially when you consider our policy prices. 
Want to help plant a tree today? 
`,
    image: giving,
  },
];

export const arrayOfOrigins = [
  "Alloy Wheel",
  "Return to Invoice",
  "Finance Gap",
  "Tyre",
  "MBI",
  "Cosmetic Repair",
  "Excess",
];



export const cardDetails = [
  {
    origin: "Alloy Wheel",
    title: "Alloy Insurance",
    description: `Scraped the curb? It’s okay. We’ve got you. With this Alloy Wheel product, you’re covered for up to £200 per claim, and up to £2,000 in Aggregate. `,
    image: alloyInsurance,
  },
  {
    origin: "Return to Invoice",
    title: "GAP Insurance",
    description: `Having your vehicle accidentally damaged, set on fire or stolen is bad enough, right? Now imagine being hit with a huge bill on top of that? Instead, cover-up with our affordable combined GAP and RTI. `,
    image: gapInsurance2,
  },
  {
    origin: "Finance Gap",
    title: "GAP Insurance",
    description: `Having your vehicle accidentally damaged, set on fire or stolen is bad enough, right? Now imagine being hit with a huge bill on top of that? Instead, cover-up with our affordable combined GAP and RTI. `,
    image: gapInsurance2,
  },
  {
    origin: "Tyre",
    title: "Tyre Insurance",
    description: `Don’t let a puncture stop you in your tracks. Whether you’ve driven over a nail, or made a new enemy (don’t worry, we’re not one to pry!). `,
    image: tyreInsurance,
  },
  {
    origin: "MBI",
    title: "Vehicle Warranty",
    description: `We’ve all been there, and there’s no way of sugar-coating it. Your vehicle breaking down due to mechanical or electrical failure is stressful. But if it ever happens to you, be thankful you signed up for our ` + checkTheme().pageTitle + ` Warranty, and stress no more. `,
    image: mbi,
  },
  {
    origin: "Cosmetic Repair",
    title: "Cosmetic Repair Insurance",
    description: `Our cosmetic repair policy is as cheap as, well… chips! Seriously, never worry again about dents, scratches, scuffs or even stone chips.   We’ll have you covered for any claim up to £250, with a generous aggregate claim limit of £3,000.`,
    image: cosmeticInsurance,
  },
  {
    origin: "Excess",
    title: "Excess Insurance",
    description: `We know it’s not your fault if somebody damages your vehicle and you have to make a motor insurance claim.
That’s why we want to minimise your expense by covering any motor excess up to your claim limit.`,
    image: mbi,
  },
];

export const couchSectionHomepage = {

  title: `We drive insurance prices down`,
  description: `We love our vehicles, but we also understand how much of a pain and burden they can be when the unexpected happens. At ` + checkTheme().pageTitle + `, it’s our job to expect the unexpected, providing you with complete, comprehensive cover, to ease your stresses away.`,
  label: "Get A Quote →",
  link: "/checkout",
  reasonTitle: `Why choose us`,
  reasons: [
    `Peace of mind`,
    `Quality protection, at a great price`,
    `Cover from Day One, on selected products`,
    `An expert team of insurance specialists`,
    `Over 20 years of experience`,
    `On hand whenever you need us`,
    `Cover available for 1, 2 or 3 years on most products`,
    `You even get to help nurture the environment`
  ],
  labelReason: "Find out more",
  linkReason: "/checkout",
}

export const benefitsList = [
  {
    origin: "MBI",
    title: `Our Vehicle Warranty Policies are insurance backed!`,
    description: `Significant benefits over our competitors products`,
    benefits: [
      `FSCS Protected & Insurance backed`,
      `Cover starts on day one`,
      `Cover available for Car, Light Commercial Vehicle, Motorcycle, Moped, Motorhome or Caravan`,
      `Cover is for mechanical and electrical failure – called “Elite” on Car / LCV or “Gold” on Motorhome, Caravan, Motorcycle or Moped`,
      `Cover for wear and tear and Boost Cover are available as an upgrade for an additional premium (subject to vehicle age and mileage limitations)`,
      `Partial or no service history prior to inception is not a problem`,
      `Mechanical and Electrical cover for vehicles up to 10 years / 100,000 miles`,
      `No age or mileage limit once cover starts`,
      `30 days cooling off period – subject to no claim having been made`,
      `Cover available for 1, 2 or 3 years`,
      `Pay in full or by monthly Direct Debit`,
      `All claims handled in house by out UK based agents`,
    ],
  },
]

export const finalStepVehicleCheckout = [
  {
    title: "Wedding Insurance",
    subtitle: "Don't leave anything to chance",
    benefits: [
      "Competitive rates",
      "Comprehensive cover",
      "A range of cover options"
    ],
    imagePNG: weddingBackgroundPng,
    imageWEBP: weddingBackgroundWebp,
    link: '/wedding-checkout'

  }
]


export const insuranceCard = [
  {
    title: "GAP Insurance",
    subtitle: "Don't get caught short",
    benefits: [
      "Competitive rates",
      "Comprehensive cover",
      "Protect against depreciation",
    ],
    imagePNG: newImg,
    imageWEBP: newImgwebp,
    link: '/checkout'
  },
  {
    title: "Vehicle Warranty",
    subtitle: "Eliminate expensive bills",
    benefits: [
      "Up to 100% cover of your breakdown costs",
      "Cheaper than the cost of repair",
      "Secure & reliable insurance",
    ],
    image: newImg2,
    imagePNG: newImg2,
    imageWEBP: newImg2Webp,
    link: '/checkout'

  },
]


export const tyreEligible = [
  {
    title: "Am I eligible for tyre insurance?",
    description: `We aim to make tyre insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you.`,
    benefitsList: "Vehicle purchased",
    benefitsListCont: "within 90 days",
    criteria: "Vehicle purchased",
    criteriaCont: "over 90 days ago",
    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover.",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },

      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },

    ],

    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. This email will also provide instructions for having your alloy wheels inspected. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
  },
]

export const benefits = [
  {
    origin: "Finance Gap",
    title: "Am I eligible for GAP insurance?",
    description:
      "We aim to make GAP insurance available to as many people as possible but there are some circumstances and vehicles that we cannot cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please do not hesitate to contact us.",
    benefitsList: "Vehicle purchased within 90 days",
    criteria: "Vehicle purchased over 90 days ago",
    pinkPig: "yes",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
    // btnPurchase: "PURCHASE COVER",
    // label: "/checkout",
    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 10 years old when you purchase cover.",
      },
      {
        itemList:
          "The vehicle has covered less than 120,000 miles when you purchase cover.",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },

      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },

    ],
  },
  {
    origin: "Tyre",
    title: "Am I eligible for tyre insurance?",
    description:
      "We aim to make tyre insurance available to as many people as possible but there are some circumstances and vehicles that we cannot cover. We ask that you read this page carefully to make sure our policy is right for you.",
    benefitsList: "Vehicle purchased within 90 days",
    criteria: "Vehicle purchased over 90 days ago",
    pinkPig: "yes",
    benefitsListItems: [
      {
        itemList: "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList: "The vehicle has covered less than 80,000 miles when you purchase cover."
      },
      {
        itemList: "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman. ",
      },
      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },
    ],


    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
  },
  {
    origin: "Alloy Wheel",
    title: "Am I eligible for alloy wheel insurance?",
    description: `We aim to make alloy wheel insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you.`,
    benefitsList: "Vehicle purchased",
    benefitsListCont: "within 90 days",
    criteria: "Vehicle purchased",
    criteriaCont: "over 90 days ago",
    pinkPig: "yes",
    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover.",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },
      {
        itemList:
          "The vehicles alloy wheels are free from damage when you purchase cover.",
      },
      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },
      {
        itemList:
          "The alloy wheels are manufacturer fitted and supplied, not aftermarket such as Wolfrace, OZ Racing etc.",
      },
      // {
      //   itemList:
      //     "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      // },
      // {
      //   itemList: "The vehicle is registered and kept principally in the UK",
      // },
    ],
    // btnPurchase: "PURCHASE COVER",
    // label: "/checkout",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. This email will also provide instructions for having your alloy wheels inspected. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
      //   {
      //     itemList:
      //       "The vehicle is less than 7 years old when you purchase cover",
      //   },
      //   {
      //     itemList:
      //       "The vehicle has covered less than 80,000 miles when you purchase cover",
      //   },
      //   {
      //     itemList:
      //       "The vehicles alloy wheels are free from damage when you purchase cover",
      //   },
      //   {
      //     itemList:
      //       "The alloy wheels are manufacturer fitted and supplied, not aftermarket such as Wolfrace, OZ Racing etc",
      //   },
      //   {
      //     itemList:
      //       "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      //   },
      //   {
      //     itemList: "The vehicle is registered and kept principally in the UK",
      //   },
    ],
  },
  {
    origin: "Excess",
    title: "Am I eligible for motor excess insurance?",
    description: `We aim to make Excess insurance available to as many people as possible but there are some circumstances and vehicles that we cannot cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please do not hesitate to contact us.`,
    benefitsList:
      "Vehicle purchased within 90 days",
    criteria: "Vehicle purchased over 90 days ago",
    bluePig: "yes",
    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover.",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },

      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },

    ],
    // btnPurchase: "PURCHASE COVER",
    // label: "/checkout",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
  },
  {
    //property name is the last word from smh website in faq MBI
    // origin: "MBI",
    // title: "Am I eligible for vehicle warranty insurance?",
    // description: `We aim to make vehicle warranty insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you.`,
    // benefitsList:
    //   "Vehicle purchased within 90 days",
    // criteria: "Vehicle purchased over 90 days ago",
    // pinkPig: "yes",
    // benefitsListItems: [
    //   {
    //     itemList:
    //       "The vehicle is less than 7 years old when you purchase cover.",
    //   },
    //   {
    //     itemList:
    //       "The vehicle has covered less than 80,000 miles when you purchase cover.",
    //   },
    //   {
    //     itemList:
    //       "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
    //   },
    //   {
    //     itemList: "The vehicle is registered and kept principally in the UK.",
    //   },
    // ],
    // // btnPurchase: "PURCHASE COVER",
    // // label: "/checkout",
    // criteriaListItems: [
    //   {
    //     itemList:
    //       "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
    //   },
    // ],
    warranty: "Can I spread the costs of the " + checkTheme().pageTitle + " vehicle warranty?",
    warrantyListItems: [
      {
        itemList:
          "You can. " + checkTheme().pageTitle + " will allow you to spread the costs of your warranty over 12 equal monthly payments.",
      },
    ],
    holiday:
      "What happens if my vehicle breaks down whilst I am away on holiday?",
    holidayListItems: [
      {
        itemList:
          "You can. " + checkTheme().pageTitle + " will allow you to spread the costs of your warranty over 12 equal monthly payments.",
      },
    ],
    claim: "If my vehicle breaks down on holiday, how do I claim?",
    claimListItems: [
      {
        itemList:
          "Please do not worry, our claims process is the same as if you were in the UK. The only difference being that you will have to pay for any costs, directly to the repairer. On your return, you will then reclaim our authorised and agreed claim amount from us.",
      },
    ],
    hours: "Can I make a claim out of hours?",
    hoursListItems: [
      {
        itemList:
          "Yes. You can register any warranty claim with us on our “Out of hours” message service but we cannot approve any potential claims until we re-open. Please ensure that you DO NOT authorise the repairer to start any work on your vehicle until we have agreed the claim.",
      },
    ],
    Vehicle: "What happens if I sell my Vehicle?",
    VehicleListItems: [
      {
        itemList:
          "If you decide to sell your vehicle before your " + checkTheme().pageTitle + " warranty has expired, you can request that we transfer any remaining period to the new owner. There would be an administration fee payable, should we agree to the transfer.",
      },
    ],
    excess: "Do I need to pay any excess?",
    excessListItems: [
      {
        itemList: "No. There is no policy excess.",
      },
    ],
    vehicle:
      "Is there any additional cost for the make or model of vehicle, or if I have a 4×4 vehicle?",
    vehicleListItems: [
      {
        itemList:
          "No. We only rate our warranty prices based on if the engine size and it`s age, so you will not be charged more just because you own a prestige make of vehicle.",
      },
    ],
    include: "What does Boost cover include?",
    includeListItems: [
      {
        itemList:
          "Our Boost cover includes the Battery, Catalytic Convertor, Satellite Navigation system, In-car Entertainment and Diesel Particulate Filter.",
      },
    ],
    repairs: "Do I need to pay for my repairs?",
    repairsListItems: [
      {
        itemList:
          "No. If you use one of our approved repairers, we will pay them on your behalf meaning that you do not need to pay and then reclaim from us.",
      },
    ],
    expires:
      "Can I buy your warranty now and start it when my manufacturer warranty expires?",
    expiresListItems: [
      {
        itemList:
          "Yes. Your " + checkTheme().pageTitle + " warranty will start from the date commencing immediately after expiry of the manufacturers’ existing warranty period.",
      },
    ],
    policy: "Can I transfer my warranty policy?",
    policyListItems: [
      {
        itemList:
          "Yes. You have two options available if you sell your vehicle.",
      },
      {
        itemList:
          "If you have sold your vehicle to a private buyer, you can transfer the unused portion of your warranty to the new owners - subject to our approval, and a payment of an administration fee. We cannot transfer the policy if you sell your vehicle to a motor dealer.",
      },
      {
        itemList:
          "Alternatively, if you have purchased a replacement vehicle, the unused warranty term can be transferred to the replacement vehicle. This can be done with us by phone or email.",
      },
    ],
  },
  {
    origin: "Cosmetic Repair",
    title: "Am I eligible for cosmetic repair insurance?",
    description: `We aim to make alloy wheel insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please don’t hesitate to `,
    benefitsList: "Vehicle purchased within 90 days",
    criteria: "Vehicle purchased over 90 days ago",
    pinkPig: "yes",
    // levelOfCover: `LEVEL OF COVER:`,
    // levelOfCoverListItems: [
    //   {
    //     itemList: `Minor Dent means damage to a metal body panel, not exceeding 30cm in diameter and where such panel has not been ripped, perforated or torn`,
    //   },
    //   {
    //     itemList: `Chip means damage to the painted vehicle not exceeding 1.5mm in diameter.`,
    //   },
    //   {
    //     itemList: `Light Scratch means damage to the vehicle body panel not exceeding 30cm in length and not extended over more than 2 (two) adjacent body panels.`,
    //   },
    //   {
    //     itemList: `Scuff(s) means light scraping of the top surface paint, not penetrating to the base material of the Vehicle and not exceeding 30cm in diameter.`,
    //   },
    // ],
    benefitsListItems: [
      {
        itemList: "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList: "The vehicle has covered less than 80,000 miles when you purchase cover.",
      },
      {
        itemList: "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },
      {
        itemList:
          "The vehicle is registered and kept principally in the UK.",
      },

    ],
    // btnPurchase: "PURCHASE COVER",
    // label: "http://localhost:8000/gapInsurance#0",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
  },
  {
    origin: "Return to Invoice",
    title: "Am I Eligible For Alloy Insurance?",
    description: `We aim to make alloy wheel insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please don’t hesitate to contact us.`,
    benefitsList: "VEHICLE PURCHASED WITHIN THE LAST 90 DAYS:",
    criteria: "VEHICLE PURCHASED OVER 90 DAYS AGO:",

    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover",
      },
      {
        itemList:
          "The vehicles alloy wheels are free from damage when you purchase cover",
      },
      {
        itemList:
          "The alloy wheels are manufacturer fitted and supplied, not aftermarket such as Wolfrace, OZ Racing etc",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },
      {
        itemList: "The vehicle is registered and kept principally in the UK",
      },
    ],
    btnPurchase: "PURCHASE COVER",
    label: "http://localhost:8000/gapInsurance#0",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover you will receive a confirmation email enclosing your policy documents. This email will also provide instructions for having your alloy wheels inspected. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover",
      },
      {
        itemList:
          "The vehicles alloy wheels are free from damage when you purchase cover",
      },
      {
        itemList:
          "The alloy wheels are manufacturer fitted and supplied, not aftermarket such as Wolfrace, OZ Racing etc",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },
      {
        itemList: "The vehicle is registered and kept principally in the UK",
      },
    ],
  },
];

export const tyreCover = [
  {
    coveredList: [
      {
        title: "Tyre Replacement",
        description:
          "We will pay for the replacement costs (subject to claim limit), for supplying and fitting the same make of tyre (subject to availability) following accidental or malicious damage. The amount we pay will be dependent upon the claim limit and period selected. A maximum of 2 claims per annum is allowed.",
      },
      {
        title: "Tyre Repair",
        description:
          "Pays the cost of punctures repairs to the tyre up to £50 (including VAT). Any benefit paid for “Repairs” will be included in the aggregate claims limit.",
      },
      {
        title: "No Excess",
        description: "There is no policy excess on our tyre insurance product.",
      },
      // {
      //   title: "National Repair Network",
      //   description: "Access to an established national Repairer network.",
      // },
      {
        title: "Policy To Cover Run Flat Tyres",
        description:
          "“Run-Flat Tyres” will be repaired wherever possible. In the event that a “Run-Flat Tyre” cannot be repaired, or the manufacturer states they should not be repaired, we will pay for an equivalent replacement tyre, subject to availability.",
      },
      {
        title: "We Pay The Going Rate",
        description:
          "Any costs for a replacement tyre will be based on the Dealer supply price or the Kwik-Fit supply price (or an alternative National supplier as appointed by the Administrator), or the claim limit, whichever is the lower.",
      },
    ],
    notCoveredList: [
      {
        title: "Wear & Tear",
        description: "We will not cover tyres that fail due to wear & tear.",
      },
      {
        title: "Certain Vehicles",
        description:
          "Vehicles which are used as an emergency vehicle, hire & reward, dispatch, courier, road racing, rallying, pace-making, speed testing or any other competitive event.",
      },
      {
        title: "Motor Trade",
        description:
          "Vehicles which are beneficially owned or operated by a company or persons involved in the business of vehicle repair, servicing, selling, trading or an employee of such a company or person.",
      },
      {
        title: "Time Of Incident",
        description:
          "Any incident or damage leading to a claim which occurred before the start of this policy.",
      },
      {
        title: "Previous Damage",
        description: "Repairs to previously damaged Tyres.",
      },
      {
        title: "Market Value",
        description:
          "Any vehicle where the invoice price / current market value exceeds £100,000.",
      },
      {
        title: "Age & Weight",
        description:
          "Any vehicle that is over 10 years old at policy inception and has a Gross Vehicle Weight of over 3.5 tonnes.",
      },
      {
        title: "Mileage",
        description: "Any vehicle over 100,000 miles at policy inception.",
      },
    ],
  },
];

export const Insurances = [
  {
    origin: "Finance Gap",
    title: "GAP insurance types",
    insuranceList: [
      {
        title: "Combination Return to Invoice",
        productGroupTitle: "Combined GAP and RTI Hire & Reward",
        description: "For vehicles bought from a dealer / internet car broker within the last 180 days, this policy pays the difference between the original invoice price you paid for your vehicle and your motor insurer’s settlement* following a total loss, or the amount required to settle any outstanding finance, whichever is the greater.",
      },
      {
        title: "Agreed Value / Shortfall Insurance",
        productGroupTitle: "Combination Shortfall Insurance",
        description: "For cars that were not bought from a dealer / internet car broker within the last 180 days or vehicles that were purchased from a dealer / internet car broker more than 180 days ago, this policy pays the difference between the Glass`s Guide Retail price for your vehicle, on the date you purchase this policy, and your motor insurer’s settlement* following a total loss, or the amount required to settle any outstanding finance, whichever is the greater.",
      },
      {
        title: "Contract Hire / Leasing GAP insurance",
        productGroupTitle: "Contract Hire & Leasing Gap",
        description: "If you bought your Vehicle on a Contract Hire or Leasing agreement within the last 180 days, this policy pays out the difference between the amount required to settle any outstanding finance and the amount of your motor insurer’s settlement* following a total loss. If your finance settlement figure is less than the amount you receive from your motor insurer, there will be no benefit due.",
      },
      {
        title: "Vehicle Replacement Insurance",
        productGroupTitle: "SMH Arch VRI",
        description: "For cars bought from a dealer / internet car broker within the last 180 days, this pays the difference between the replacement price for the equivalent make / model of your vehicle and your motor insurer’s settlement* following a total loss, or the amount required to settle any outstanding finance, whichever is the greater. If an equivalent make / model is no longer available, the claim will be settled based on the original invoice price you paid less your motor insurer’s settlement*",
      },
    ]
  }
]

export const Cover = [
  {
    origin: "Finance Gap",
    title: "Flexible GAP cover",
    secondTitle: "What's not covered",
    description: "We want to keep things easy for you. That’s why most brands and electric cars can be covered, and you can easily transfer your " + checkTheme().pageTitle + " GAP insurance policy if you change your vehicle.",
    coveredList: [
      {
        title: "Flexible Cover",
        description:
          "All of our GAP products allow for pro- rata refunds for the remainder of any unused term, should you sell the vehicle or decide you do not need cover, or you can transfer any remaining term to a replacement vehicle should you decide to change vehicles, but keep the cover in place.",
      },
      {
        title: "Vehicle Make & Model",
        description:
          "Covers all brands except Bugatti, Hummer, Honda NSX models, Lamborghini, Lotus, Maserati and Maybach, See the Terms and conditions for all vehicle eligibility criteria and exclusions.",
      },
      {
        title: "Electric Vehicles",
        description: "Electric vehicles are covered.",
      },
      {
        title: "Transferable Policy",
        description:
          "Transfer the policy to another vehicle that meets the eligibility criteria.",
      },
    ],
    notCoveredList: [
      {
        title: "Market Value",
        description:
          "Any vehicle where the invoice price / current market value exceeds £100,000.",
      },
      {
        title: "Age & Weight",
        description: "Any vehicle that is over 10 years old at policy inception and has a Gross Vehicle Weight of over 3.5 tonnes.",
      },
      {
        title: "Mileage",
        description:
          "Any vehicle over 100,000 miles at policy inception.",
      },
      // {
      //   title: "Supplying Dealer",
      //   description:
      //     "Any vehicle not purchased from an approved source within the last 90 days.",
      // },
    ],
  },
  {
    origin: "Tyre",
    title: "Going the extra mile for you",
    secondTitle: "What's not covered",
    description: "Buy tyre insurance from " + checkTheme().pageTitle + " and watch us go the extra mile for you, providing run-flat tyre insurance to replace your tyres. Unlike other insurance, we also allow you to make a second claim on the same tyre, should any problems occur.",
    coveredList: [
      {
        title: "Tyre Replacement",
        description:
          "We will pay for the replacement costs (subject to claim limit), for supplying and fitting the same make of tyre (subject to availability) following accidental or malicious damage. The amount we pay will be dependent upon the claim limit and period selected. A maximum of 2 claims per annum is allowed.",
      },
      {
        title: "Tyre Repair",
        description:
          "Pays the cost of punctures repairs to the tyre up to £50 (including VAT). Any benefit paid for “Repairs” will be included in the aggregate claims limit.",
      },
      {
        title: "No Excess",
        description: "There is no policy excess on our tyre insurance product.",
      },
      // {
      //   title: "National Repair Network",
      //   description: "Access to an established national Repairer network.",
      // },
      {
        title: "Policy To Cover Run Flat Tyres",
        description:
          "“Run-Flat Tyres” will be repaired wherever possible. In the event that a “Run-Flat Tyre” cannot be repaired, or the manufacturer states they should not be repaired, we will pay for an equivalent replacement tyre, subject to availability.",
      },
      {
        title: "We Pay The Going Rate",
        description:
          "Any costs for a replacement tyre will be based on the Dealer supply price or the Kwik-Fit supply price (or an alternative National supplier as appointed by the Administrator), or the claim limit, whichever is the lower.",
      },
    ],
    notCoveredList: [
      {
        title: "Wear & Tear",
        description: "We will not cover tyres that fail due to wear & tear.",
      },
      {
        title: "Certain Vehicles",
        description:
          "Vehicles which are used as an emergency vehicle, hire & reward, dispatch, courier, road racing, rallying, pace-making, speed testing or any other competitive event.",
      },


      {
        title: "Previous Damage",
        description: "Repairs to previously damaged Tyres.",
      },
      {
        title: "Market Value",
        description:
          "Any vehicle where the invoice price / current market value exceeds £100,000.",
      },


    ],
  },

  {
    origin: "MBI",
    title: "Give your cover a Boost",
    description: "Say no to unwanted repair bills. Shout YES to " + checkTheme().pageTitle + "’s instant warranty cover, which keeps you fully protected when your vehicle lets you down. For anything beyond wear and tear, we offer a premium package which includes your car’s battery, sat-nav, in-car entertainment and more!",
    coveredList: [
      {
        title: "Unexpected Costs",
        description:
          "A used vehicle is more likely to suffer a mechanical failure than a brand-new vehicle and we do not buy any age of vehicle on the presumption it is going to break down.",
      },
      {
        title: "Cheaper than the Cost of a Repair",
        description:
          "We may well have some type of insurance that we elect to pay for, and we may pay for that annually or on a monthly basis and yet we may never have to use it.",
      },
      {
        title: "Expenses other than an Actual Repair",
        description:
          "Your vehicle may not be able to be repaired on the same day. Depending on the failure it may well be out of action for a few days or even weeks.",
      },
      {
        title: "Wear & Tear",
        description:
          "Wear and Tear cover is available as an upgrade and (if selected and the additional premium is paid), means that if a part breaks, or fails, due to the mileage or running of the vehicle, it is typically considered as failure due to “Wear & Tear”…",
      },
      {
        title: "Boost",
        description:
          "Boost cover is available as an upgrade on all our warranties and (if selected and the additional premium is paid), We would cover the Battery, Catalytic Convertor, Satellite Navigation system, In-car Entertainment and Diesel Particulate Filter.",
      },
      // {
      //   title: "Expenses other than an Actual Repair",
      //   description:
      //     "Your vehicle may not be able to be repaired on the same day. Depending on the failure it may well be out of action for a few days or even weeks.",
      // },
    ]
  },
  {
    origin: "Cosmetic Repair",
    title: "High claims limit, low excess",
    secondTitle: "What's not covered",
    description: "Simply register online today and put an end to your stone dent nightmares. " + checkTheme().pageTitle + "’s Cosmetic Insurance covers your vehicle for both everyday and business use (if purchased), providing repair costs of up to £250 per claim, with an excess charge of just £10.",
    coveredList: [
      {
        title: "Damage Caused To Your Vehicle",
        description:
          "With " + checkTheme().pageTitle + "’s Cosmetic Repair Insurance, the vehicle is covered for damage caused by everyday motoring or business use (if Business Use product purchased).",
      },
      {
        title: "High Claims Limit",
        description:
          "Customers can claim up to £250 per individual repair and £3,000 in aggregate over the period of insurance. Any repairs that exceed £250 are not considered to be minor damage.",
      },
      {
        title: "Low Excess",
        description:
          "There is a £10 excess payable on each individual repair made.",
      },
      {
        title: "Minor Dent",
        description:
          "Minor Dent means damage to a metal body panel, not exceeding 30cm in diameter and where such panel has not been ripped, perforated or torn.",
      },
      {
        title: "Chips",
        description:
          "Chip means damage to the painted vehicle not exceeding 1.5mm in diameter",
      },
      {
        title: "Light Scratches",
        description:
          "Light Scratch means damage to the vehicle body panel not exceeding 30cm in length and not extended over more than 2 (two) adjacent body panels.",
      },
      {
        title: "Scuffs",
        description:
          "Scuff(s) means light scraping of the top surface paint, not penetrating to the base material of the Vehicle and not exceeding 30cm in diameter.",
      },
      {
        title: "Contribution",
        description:
          "If we are unable to repair the damage we will pay a contribution towards repair.",
      },
    ],
    notCoveredList: [
      {
        title: "Market Value",
        description:
          "Any vehicle where the invoice price / current market value exceeds £100,000.",
      },
      {
        title: "Age & Weight",
        description:
          "Any vehicle that is over 10 years old at policy inception and has a Gross Vehicle Weight of over 3.5 tonnes.",
      },
      {
        title: "Mileage",
        description: "Any vehicle over 100,000 miles at policy inception.",
      },
      {
        title: "Supplying Dealer",
        description:
          "Any vehicle not purchased from an approved source within the last 90 days.",
      },
    ],
  },
  {
    origin: "Alloy Wheel",
    title: "Start covering the cost of accidental damage repair today",
    secondTitle: "What's not covered",
    description: "Get the alloy wheel insurance that's right for you by choosing from our two main levels of cover, each designed to best suit your wheel size and alloy design. Choose between 12 month, 24 month or 36 month alloy wheel insurance.",
    coveredList: [
      {
        title: "Replacement Alloy Wheel",
        description:
          "If the alloy wheel can’t be repaired, we will contribute towards a replacement.",
      },
      {
        title: "Instant Protection",
        description:
          "Instant Cover for vehicles purchased within last 90 days.",
      },
      {
        title: "No Excess",
        description:
          "There is No Excess on this policy if you should need to claim.",
      },
      {
        title: "Diamond Cut & Polished",
        description:
          "Cover for Diamond Cut & Polished with our Gold level of cover.",
      },
      {
        title: "Transferable",
        description:
          "Transferable to a replacement vehicle (subject to £15 admin fee).",
      },
      {
        title: "Purchase Cover Anytime",
        description:
          "Cover for vehicles purchased outside of the last 90 days – subject to inspection by us or our approved repairer.",
      },
    ],
    notCoveredList: [
      {
        title: "Certain Vehicles",
        description:
          "Vehicles which are used as an emergency vehicle, hire & reward, dispatch, courier, road racing, rallying, pace-making, speed testing or any other competitive event.",
      },
      {
        title: "Time Of Incident",
        description:
          "Any incident or damage leading to a claim which occurred before the start of this policy.",
      },
      {
        title: "Previous Damage",
        description: "Repairs to previously damaged Alloy Wheels.",
      },
      {
        title: "Alloy Wheel Theft",
        description: "Theft of Alloy Wheels.",
      },
    ],
  },
  {
    origin: "Return to Invoice",
    coveredList: [
      {
        title: "Flexible Cover",
        description:
          "All of our GAP products allow for pro- rata refunds for the remainder of any unused term, should you sell the vehicle or decide you do not need cover, or you can transfer any remaining term to a replacement vehicle should you decide to change vehicles, but keep the cover in place.",
      },
      {
        title: "Vehicle Make & Model",
        description:
          "Covers all brands except Bugatti, Hummer, Honda NSX models, Lamborghini, Lotus, Maserati and Maybach, See the Terms and conditions for all vehicle eligibility criteria and exclusions.",
      },
      {
        title: "Electric Vehicles",
        description: "Electric vehciles are covered.",
      },
      {
        title: "Transferable Policy",
        description:
          "Transfer the policy to another vehicle that meets the eligibility criteria.",
      },
    ],
    notCoveredList: [
      {
        title: "Invoice Price / Current Market Value",
        description:
          "The invoice price / current market value of insured vehicle must not exceed £125,000. (£75,000 on GAP).",
      },
      {
        title: "Vehicle Age",
        description: "Any vehicle over 10 years old at inception of the policy",
      },
      {
        title: "Vehicle Weight",
        description:
          "Any vehicle weighing over 3.5 tonnes (7 tonnes on Motorhomes).",
      },
      {
        title: "Maximum Mileage",
        description:
          "Any vehicle with a mileage over 100,000 miles at policy inception.",
      },
      {
        title: "Vehicle Purchase Conditions",
        description:
          "Any vehicle not purchased from an approved source within the last 180 days (excluding Shortfall).",
      },
    ],
  },
  {
    origin: "Wedding",
    title: "What does wedding insurance cover?",
    description: "Ensure your big day is fully covered with " + checkTheme().pageTitle + "’s insurance packages including everything from cancellations to wedding attire, rings to cakes, flowers to transportation, and much more! Take a look for yourself at the extensive list of items and services that " + checkTheme().pageTitle + "’s great-value Wedding Insurance has to offer, depending on the level cover selected:",
    coveredList: [
      {
        title: "Cancellation Cover",
        description:
          "With " + checkTheme().pageTitle + "’s Cosmetic Repair Insurance, the vehicle is covered for damage caused by everyday motoring or business use (if Business Use product purchased).",
      },
      {
        title: "Re-arrangement Cover",
        description:
          "In the event of the cancellation of your planned wedding, it may be that you wish to rearrange an alternative ceremony.",
      },
      {
        title: "Ceremonial Attire",
        description:
          "All " + checkTheme().pageTitle + " Wedding Insurance policies offer cover to protect you against the accidental complete loss of or severe damage to the ceremonial attire which renders the items unwearable and where the purchase or hire of alternatives is not possible. Whether that’s the caterers, the photographer, or any other wedding supplier, in this current economic environment nothing is certain. This can obviously have huge implications for your wedding especially if you are unable to recoup any deposits you may have paid. If this does occur then, in line with the policy wording, you will be covered for any irrecoverable deposits and any additional costs you face in arranging an alternative, equivalent service between £3,000* and £15,000*. Cover for Financial Failure is included as standard on all Wedding Insurance policies because we want you to have the wedding you have always dreamed of and aren’t left out of pocket because of something out of your control.",
      },
      {
        title: "Wedding Gifts",
        description:
          "With our Wedding Gifts Coverage, included in all of our policies, your gifts are covered for up to 7 days before your big day and for 24 hours afterwards.",
      },
      {
        title: "Rings, Flowers, Gifts and Cake",
        description:
          "You may be surprised to hear that the average couple spends around £300 on the cake for their big day which in itself is a big purchase, even if it’s a relatively small proportion of your wedding spend.",
      },
      {
        title: "Wedding Cars & Transport",
        description:
          "All " + checkTheme().pageTitle + " Wedding Insurance policies offer financial protection for unforeseen additional costs to arrange alternative transport.",
      },
      {
        title: "Photography & Video",
        description:
          "There is a long list of things that can go wrong when it comes to wedding photos and wedding videos.",
      },
      {
        title: "Financial Failure",
        description:
          "It’s fair to say most couples worry about some of their wedding service suppliers going under before their big day.",
      },
      {
        title: "Personal Accident",
        description:
          "All " + checkTheme().pageTitle + " Wedding Insurance policies offer cover for the marrying couple against personal accident, which includes the loss of sight, a limb or any permanent disablement within 12 months of a covered accident.",
      },
      {
        title: "Professional Counselling",
        description:
          "All " + checkTheme().pageTitle + " Wedding Insurance policies offer cover in respect of professional counselling that is recommended by a general practitioner for either of the marrying couple.",
      },
      {
        title: "Legal Expenses",
        description:
          "All " + checkTheme().pageTitle + " Wedding Insurance policies offer cover for any legal costs and expenses incurred by you, in the pursuit of legal proceedings by you or your personal representative(s).",
      },
      {
        title: "Public Liability",
        description:
          "All the " + checkTheme().pageTitle + " Wedding Insurance policies include £2 million of Public Liability cover for the Marrying Couple as standard.",
      },
      {
        title: "Marquee & Event Equipment",
        description:
          "If you are holding your Wedding ceremony or reception in a standalone marquee, then it makes sense to cover this within your Wedding Insurance policy.",
      },
      {
        title: "Ceremonial Swords",
        description:
          "Ceremonial Swords can play a big part in couples’ weddings or civil partnerships, typically those with in the armed forces but also in some religious ceremonies.",
      },
      {
        title: "Coronavirus Cover",
        description:
          "After everything that has happened across the world over the last 2 years, you might decide to take the upgrade to add coronavirus cover.",
      },
    ]
  },
  {
    origin: "Excess",
    title: "What sets our excess insurance apart from the competition?",
    description: "",
    coveredList: [
      {
        title: "What We Cover",
        description: "We will cover the lower of either the claim limit selected or the excess due on their motor insurance policy, subject to: The vehicle repairs in connection with the claim under their Motor Insurance Policy being completed or; In the event of the Insured Vehicle being declared a Total Loss by their Motor Insurer, the replacement vehicle purchased by them from our Approved Dealer Network.",
      },
    ],
  },
];
export const benefitsTyre = [
  {
    title: "Benefits Of Using " + checkTheme().pageTitle + ".com",
    description:
      "We aim to make GAP insurance available to as many people as possible but there are some circumstances and vehicles that we cannot cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please do not hesitate to contact us.",
    benefitsList: "SIGNIFICANT BENEFITS OVER OUR COMPETITORS PRODUCTS",
    criteria: "ELIGIBILITY CRITERIA",
    benefitsListItems: [
      {
        itemList: "Highly competitive pricing.",
      },
      {
        itemList:
          "Wider vehicle eligibility criteria and more flexible cover than competitors.",
      },
      {
        itemList:
          "Our GAP insurance products can be purchased up to 180 days after you take delivery of the vehicle. (Shortfall Insurance is available if more than 180 days)",
      },
      {
        itemList:
          "Direct and simple claims process and we handle all claims in-house, in the UK.",
      },
      {
        itemList:
          "Transferable to a replacement vehicle that meets the eligibility criteria – subject to an administration fee",
      },
      {
        itemList:
          "Cover available for most vehicle makes / models, including electric vehicles",
      },
    ],
    btnPurchase: "PURCHASE COVER",
    label: "/checkout",
    criteriaListItems: [
      {
        itemList:
          "The invoice price / current market value of insured vehicle does not exceed £125,000. (£75,000 on GAP).",
      },
      {
        itemList:
          "The insured vehicle is less than 10 years old at inception of the policy and has a maximum GVW of 3.5 tonnes (7 tonnes on Motorhomes).",
      },
      {
        itemList:
          "The insured vehicle has covered less than 100,000 miles at inception of the policy",
      },
      {
        itemList:
          "The insured vehicle was purchased from an approved source within the last 180 days. If the vehicle was purchased separately or more than 180 days ago, you can still purchase our Shortfall Insurance.",
      },
    ],
  },
];
export const gapInsurance = [
  {
    title: "gap insurance",
    description:
      checkTheme().pageTitle + ".com offers some of the most comprehensive GAP products on the market with competitive rates to match.",
    link: "https://" + checkTheme().pageUrl + "/gap-insurance/#whats-covered",
    label1: "What is covered?",
    label2: "Purchase cover",
  },
];

export const howWork = [
  {
    title: "How Does GAP Insurance Work?",
    description:
      "We will provide you with protection against financial loss they may incur as a result of their vehicle being a total write-off due to accident, fire, vandalism, flood or theft.",
    conclusion:
      "GAP Insurance is a supplementary insurance that protects you financially when your vehicle is written off. Please remember that if your vehicle is written off, your own motor insurance company is only ever legally obliged to offer you the value of your vehicle on the day it was written off. With the average vehicle predicted to depreciate by up to 50% within the first three years alone, this means that the amount that you are offered in settlement can be, and often is, thousands of pounds less than the original amount that you paid.",
  },
];
export const returnInvoice = [
  {
    title: "Combination Return To Invoice",
    listItemDescription1: "The original purchase price of the Vehicle or",
    listItemDescription2:
      "The outstanding settlement value on the finance agreement, subject to the original amount financed being less than or equal to the Vehicle purchase price.",
    description:
      "If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the financial shortfall between the Insured Value and the greater of: ",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];
export const invoiceHire = [
  {
    title: "Combination Return to Invoice – Hire & Reward",
    description:
      "Specifically designed for Taxis, Driving Schools, Couriers and Chauffeurs, If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the financial shortfall between the Insured Value and the greater of: ",
    listItemDescription1: "The original purchase price of the Vehicle or",
    listItemDescription2:
      "The outstanding settlement value on the finance agreement, subject to the original amount financed being less than or equal to the Vehicle purchase price.",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];
export const leasing = [
  {
    title: "Contract Hire & Leasing GAP",
    description:
      "If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the difference between the Insured Value of Your Vehicle and the Outstanding Balance under Your Contract Hire or Leasing Agreement.",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];
export const leasingHire = [
  {
    title: "Contract Hire & Leasing GAP – Hire & Reward",
    description:
      "Specifically designed for Taxis, Driving Schools, Couriers and Chauffeurs, If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the difference between the Insured Value of Your Vehicle and the Outstanding Balance under Your Contract Hire or Leasing Agreement.",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];
export const shortfall = [
  {
    title: "Shortfall Cover",
    description:
      "If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the financial shortfall between the Insured Value and the greater of:  ",
    listItemDescription1:
      "The current “Retail” market value of the Vehicle as shown in Glass`s Guide, on the date you purchase this insurance or",
    listItemDescription2:
      "The outstanding settlement value on the finance agreement, subject to the original amount financed being less than or equal to the Vehicle purchase price.",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];

export const gapInsuranceCards = [
  {
    title: "24 months",
    price: "£85",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 months",
    price: "£115",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£160",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
];
export const gapInsuranceCards2 = [
  {
    title: "24 months",
    price: "£199",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined GAP & RTI Hire & Reward",
    sublineType: "",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 months",
    price: "£239",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined GAP & RTI Hire & Reward",
    sublineType: "",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£339",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined GAP & RTI Hire & Reward",
    sublineType: "",
    months: "48 Months Cover",
    btnPurchase: "purchase cover",
  },
];

export const gapInsuranceCards3 = [
  {
    title: "24 months",
    price: "£85",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 Months",
    price: "£103",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£160",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP",
    months: "48 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "60 Months",
    price: "£112",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP",
    months: "60 Months Cover",
    btnPurchase: "purchase cover",
  },
];

export const gapInsuranceCards4 = [
  {
    title: "24 months",
    price: "£210",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 Months",
    price: "£230",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£250",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "48 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "60 Months",
    price: "£270",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "60 Months Cover",
    btnPurchase: "purchase cover",
  },
];

export const gapInsuranceCards5 = [
  {
    title: "24 months",
    price: "£85",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Shortfall Cover",
    sublineType: "",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 Months",
    price: "£115",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Shortfall Cover",
    sublineType: "",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£160",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Shortfall Cover",
    sublineType: "",
    months: "48 Months Cover",
    btnPurchase: "purchase cover",
  },
];

export const aboutUs = [
  {
    title: "WHAT OUR CUSTOMERS SAY ABOUT US",
    testimonials:
      ' " I saved a lot of money on both my alloy and gap insurance compared to what my dealer quoted me, friendly, and fast service. I would definitely recommend ' + checkTheme().pageUrl,
    author: "- Mr Bronwick",
  },
];

// ----------------------------------Tyre Insurance---------------

export const tyreInsuranceHeader = [
  {
    title: "Tyre Insurance",
    description:
      "We will protect the customer in the event of sustaining either accidental or malicious damage to tyres fitted to the Insured Vehicle.",
    link: "#0",
    label1: "Purchase cover",
    label2: "What's covered?",
  },
];

export const tyreInsuranceParagraph = [
  {
    title: "Tyre Insurance For Your Vehicle",
    description:
      "We will protect the customer in the event of sustaining either accidental or malicious damage to tyres fitted to the Insured Vehicle.",
    conclusion:
      "The cover includes the cost of replacement or repair of a tyre, up to the claim limit purchased, as long as the tyre tread depth is above the UK “Legal Roadworthy limit” at the time of the claim.",
  },
];

export const tyreStandardType = [
  {
    title: "Standard Tyre insurance prices",
    description:
      "Our standard tyre insurance, provides protection against sustaining either accidental or malicious damage to tyres under 18″ in size. Excluding wear & tear.   * prices may vary due to your vehicle eligibility",
  },
];
export const variousTypesOfGap = [
  {
    title: "What are the various types of GAP available via " + checkTheme().pageTitle + "*",
    description: "* prices may vary due to your vehicle eligibility",
  },
];
export const tyreOverType = [
  {
    title: "18″ & Over Tyre Insurance Prices*",
    description:
      "Our 18″ & over tyre insurance, provides protection against sustaining either accidental or malicious damage to tyres 18″ or over. Excluding wear & tear.   * prices may vary due to your vehicle eligibility",
  },
];

export const standardTypeCard = [
  {
    title: "£125 Limit",
    price: "£99",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "No Excess",
    sublineType: "Up To 2 Claims Per Year",
    months: "24 Months Cover",
    tyresUnder: "Tyres Under 18",
    btnPurchase: "purchase cover",
  },
  {
    title: "£125 Limit",
    price: "£159",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "No Excess",
    sublineType: "Up To 2 Claims Per Year",
    months: "36 Months Cover",
    tyresUnder: "Tyres Under 18",
    btnPurchase: "purchase cover",
  },
];

export const overTypeCard = [
  {
    title: "£250 Limit",
    price: "£249",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "No Excess",
    sublineType: "Up To 2 Claims Per Year",
    months: "24 Months Cover",
    tyresUnder: "Covers 18″ & Over",
    btnPurchase: "purchase cover",
  },
  {
    title: "£250 Limit",
    price: "£379",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "No Excess",
    sublineType: "Up To 2 Claims Per Year",
    months: "36 Months Cover",
    tyresUnder: "Covers 18″ & Over",
    btnPurchase: "purchase cover",
  },
];

/* Homepage header imports and exports */

export const homepageTitle = "Save Time, Save The Hassle, " + checkTheme().pageTitle + ".com";
export const homepageDescription = `GAP insurance, alloys, cosmetic, tyre, mechanical breakdown warranties..
these are the fiddly, twiddly types of insurance you don't usually think about. But without them, you might develop an irrational fear of car parks and kerbs. And how would you do your shopping then?
`;

export const plantImg = plant;
export const fcaImg = fca;
export const bibaImg = biba;

/*Homepage cards */

export const homepageCards = [
  {
    origin: "Return to Invoice",
    title: "Return to invoice",
    description:
      " Combined shortfall insurance is designed to pay the shortfall between the insurer’s market value and the original purchase price of your vehicle..",
    image: gap26,
    findOutMoreButton: "Find out more",
    linkList: "/gap-insurance",
    getQuoteButton: "Get a quote",
  },
  {
    origin: "Finance Gap",
    title: "Gap Insurance",
    description: `Having your vehicle accidentally damaged, set on fire or stolen is bad enough, right?
Now imagine being hit with a huge bill on top of that? 
Instead, cover-up with our affordable combined GAP and RTI insurance, designed to 
pay the shortfall between the insurer’s market value and the original cost of your 
vehicle.
`,
    image: gap26,
    findOutMoreButton: "Find out more",
    linkList: "/gap-insurance",
    getQuoteButton: "Get a quote",
  },
  {
    origin: "Alloy Wheel",
    title: "Alloy Insurance",
    description: `Scraped the curb? It’s okay. We’ve got you. 
    With this Alloy Wheel product, you’re covered for 
    up to £200 per claim, and up to £2,000 in Aggregate.`,
    image: alloy26,
    findOutMoreButton: "Find out more",
    linkList: "/alloy-insurance",
    getQuoteButton: "Get a quote",
  },

  {
    origin: "Tyre",
    title: "Tyre Insurance",
    description: `Don’t let a puncture stop you in your tracks.
Whether you’ve driven over a nail, or made a new enemy (don’t worry, we’re not one 
to pry!), our policy covers you for both accidental and malicious damage.`,
    image: tyre26,
    findOutMoreButton: "Find out more",
    linkList: "/tyre-insurance",
    getQuoteButton: "Get a quote",
  },
  {
    origin: "MBI",
    title: "Vehicle Warranty",
    description: `We’ve all been there, and there’s no way of sugar-coating it.
Your vehicle breaking down is stress. Pure stress.
But if it ever happens to you, and you’re sat on the hard shoulder in a huff, be 
thankful you signed up for our 12 months Elite Vehicle Warranty, and 
stress no more.`,
    image: mbi26,
    findOutMoreButton: "Find out more",
    linkList: "/vehicle-warranty-insurance",
    getQuoteButton: "Get a quote",
  },
  {
    origin: "Cosmetic Repair",
    title: "Cosmetic Repair Insurance",
    description: `Our cosmetic repair policy is as cheap as, well… chips! 
Seriously, never worry again about dents, scratches, scuffs or even stone chips. 
We’ll have you covered for any claim up to £250, with a generous aggregate claim 
limit of £3,000. 
Is your vehicle for business use? No worries, we’ve got you covered, too.`,
    image: cosmetic26,
    findOutMoreButton: "Find out more",
    linkList: "/cosmetic-repair-insurance",
    getQuoteButton: "Get a quote",
  },

  {
    origin: "Excess",
    title: "Excess Insurance",
    description: `We know it’s not your fault if somebody damagers your vehicle.
We also know you’re not to blame if your vehicle breaks down unexpectedly.
That’s why we want to minimise your expense in the event that you have to make a claim.
`+ checkTheme().pageTitle + `’s excess insurance allows us to take the hit when it comes to your excess fee, leaving you stress-free.`,
    image: mbi26,
    findOutMoreButton: "Find out more",
    linkList: "/excess-insurance",
    getQuoteButton: "Get a quote",
  },
];

/*Homepage cards */

export const couchTitle = "You're buying peace of mind";
export const couchContent =
  "We’re 110% kosher, regulated by the Financial Conduct Authority, a member of BIBA, and we’ve got our cycling proficiency badges. We also currently provide products to over £180m-worth of UK vehicles. So you can be sure there’s no funny monkey business with us.";
export const couchImg = relaxing;

/*Card Title */
export const formTitle = `We've got your insurance covered`;

/*Rating image */

export const ratingImg = rating;

/*Secondary pages header */

export const secondaryPagesHeader = [
  {
    origin: "MBI",
    title: "Vehicle Warranty",
    description:
      "We can protect you from the unexpected expense of bills for mechanical repairs, with our Mechanical Breakdown Insurance.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: mbi,
    imgwebp: checkTheme().imgWarranty,
    imgpng: checkTheme().imgWarrantypng,

  },
  {
    origin: "Alloy Wheel",
    title: "Alloy Wheel Insurance",
    description:
      "Cover the cost of alloy wheel repairs, should they become damaged.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: alloyHeader,
    imgwebp: checkTheme().imgAlloy,
    imgpng: checkTheme().imgAlloypng,
  },
  {
    origin: "Finance Gap",
    title: "GAP Insurance",
    description:
      checkTheme().pageTitle + ".com offers some of the most comprehensive GAP products on the market with competitive rates to match.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: gapHeader,
    link: "#",
    label: "What is covered?",
    imgwebp: checkTheme().imgGAP,
    imgpng: checkTheme().imgGAPpng,
  },
  {
    origin: "Tyre",
    title: "Tyre Insurance",
    description:
      "We will protect the customer in the event of sustaining either accidental or malicious damage to tyres fitted to the Insured Vehicle.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: tyreHeader,
    imgwebp: checkTheme().imgTyre,
    imgpng: checkTheme().imgTyrepng,
  },
  {
    origin: "Cosmetic Repair",
    title: "Cosmetic Insurance",
    description:
      "Our cosmetic insurance, sometimes called Cosmetic Repair, Scratch & Dent or SMART Dent, provides protection for minor dents, stone chips, scratches and scuffs.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: cosmetic,
    imgwebp: checkTheme().imgCosmetic,
    imgpng: checkTheme().imgCosmeticpng,
  },
  {
    origin: "Excess",
    title: "Excess Insurance",
    description: checkTheme().pageTitle + `’s excess insurance allows us to take the hit when it comes to your excess fee, leaving you stress-free.`,
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: mbi26,
    imgwebp: checkTheme().imgExcess,
    imgpng: checkTheme().imgExcesspng,
  },
  {
    origin: "Return to Invoice",
    title: "RETURN TO INVOICE",
    description:
      "Combined GAP and RTI insurance is designed to pay the shortfall between the insurer’s market value and the original purchase price of your vehicle, following the total loss of your vehicle due to accidental damage, fire or theft. Claim limits from £5,000 to £50,000 over periods of 2-4 years available.",
    image: alloyHeader,
  },

];


// header paragraph

export const headerParagraph = {
  "About Us": [
    {
      title: `Competitive, quality vehicle protection products – all under one roof!`,
      description: `We started ` + checkTheme().pageTitle + ` for one core reason – I bet you’ll never guess what that 
  was? That’s right! To save our customers both time and money. 
  But not only that.\n
  The team here at `+ checkTheme().pageTitle + ` believe in keeping things simple, which is why we 
  cater for all of your vehicle’s needs, all in-house, all under one roof. 
  That way, you don’t have to keep shopping around each time any slight thing 
  happens with your vehicle.`,
      button: "abc",
    },
  ],
  "Tree Planted": [
    {
      title: "With every policy purchased from " + checkTheme().pageTitle + " a tree is planted",
      description: `Responsibility is an important word to us here at ` + checkTheme().pageTitle + `. Not only are we responsible for your car’s every need, 
      but we do our part in being responsible for nurturing our planet. That’s why for every policy sold, we plant one tree. 
      If you think about it, that’s A LOT of trees. Especially when you consider our policy prices.`,
      subtitle: "Want to help plant a tree today?",
      button: "abc",
      imageLeft: treeLeft,
    }
  ],
  "Alloy Wheel": [
    {
      title: "Alloy Insurance - Save up to £120 per alloy",
      description: `Scraped your alloy wheels? No problem.
      
Hit a kerb? Don’t worry.
    
Driven over a pothole? It’s all good. We’ve got you covered.`,
    },
    {
      title: "Pristine Alloy Wheels - Without the Cost of Repair",
      description: `Alloy wheels look brilliant.

But what’s not brilliant is being hit with a large bill to fix unwanted scuffs or scratches.

So don’t let it happen. Cover your tracks. Protect unwanted alloy wheel repair payment with Alloy Insurance from `+ checkTheme().pageTitle + `, saving up to £120 for each alloy that gets damaged.`,
    },
    {
      title: "Protect yourself and your vehicle against unforeseen costs",
      description: `At ` + checkTheme().pageTitle + `, we are here to offer you the ultimate peace of mind when it comes to your vehicle.`,
    },
    {
      title: "Start Covering the Cost of Accidental Damage Repair Today",
      description: `Get the alloy wheel insurance that’s right for you by choosing from our two main levels of cover, each designed to best suit your wheel size and alloy design.
 
Choose between 12 month, 24 month or 36 month alloy wheel insurance.
      
Want more details? Then please see below.`,
    },
  ],
  Tyre: [
    {
      title: "Tyre Insurance - Cover All Replacement Tyre Costs",
      description: `Protect against accidental damage to your vehicle’s tyres with this great-value tyre insurance from ` + checkTheme().pageTitle + `.`,
    },
    {
      title: "Protect Against Tyre Repair Costs",
      description: `Ease the pain of punctures and cover the cost of repair to your damaged tyres with ` + checkTheme().pageTitle + `’s insurance. `,
    },
    {
      title: "Going The Extra Mile for You",
      description: `Buy tyre insurance from ` + checkTheme().pageTitle + ` and watch us go the extra mile for you, providing run-flat tyre insurance to replace your tyres.

Unlike other insurance, we also allow you to make a second claim on the same tyre, should any problems occur. 

Want more details? Then please see below.`,
    },
  ],
  "Finance Gap": [
    {
      title: "GAP Insurance - Don’t Get Caught Short",
      description: `When we buy our vehicles, the last thing we ever imagine is the possibility it may be written off. 

That’s why many vehicle owners are left out of pocket when the unthinkable happens, as all insurers are only legally obliged to compensate the current market value of your vehicle.

GAP Insurance from `+ checkTheme().pageTitle + ` provides protection against financial loss that may incur as a result of your vehicle being a total write-off due to accident, fire, vandalism, flood or theft.`,
    },
    {
      title: "Protect Against Depreciation",
      description: `As you will know, the moment a vehicle is driven off the showroom, its value begins to drop like a stone. 

In fact, the average vehicle is predicted to depreciate by up to 50% within the first three years alone.

This means the amount that you are offered in settlement can be, and often is, thousands of pounds less than the original 
amount that you paid.

`+ checkTheme().pageTitle + ` provides a solution to this problem, offering comprehensive cover which significantly boosts your payment 
in the event of a write-off.`,
    },
    {
      title: "Flexible GAP cover ",
      description: `We want to keep things easy for you.

That’s why most brands and electric cars can be covered, and you can easily transfer your `+ checkTheme().pageTitle + ` GAP insurance policy if you change your vehicle.

Want more details? Then please see below.
`,
    },
  ],

  "Cosmetic Repair": [
    {
      title: "Cosmetic Insurance - Great-Value SMART Repair ",
      description: `Drive the car you love without the hassle of damage costs thanks to ` + checkTheme().pageTitle + `’s cosmetic repair insurance, providing cover for minor dents, stone chips, scratches and scuffs.`,
    },
    {
      title: "Great-Value SMART Repair Insurance ",
      description: `Insurance for Small Medium Area Repair Technology (or SMART Repair) can be very expensive, especially when purchased from your local car dealer. 

That’s where `+ checkTheme().pageTitle + ` comes in, like our name suggests, to help you cut costs and offer you vehicle repair by one of our authorised specialists, helping to keep your vehicle in showroom condition.
`,
    },
    {
      title: "High Claims Limit, Low Excess",
      description: `Simply register online today and put an end to your stone dent nightmares.

`+ checkTheme().pageTitle + `’s Cosmetic Insurance covers your vehicle for both everyday and business use (if purchased), providing repair costs of up to £250 per claim, with an excess charge of just £10. 

Want more details? Then please see below`,
    },
  ],

  MBI: [
    {
      title: "Vehicle Warranty – It's time to eliminate expensive bills",
      description: `With ` + checkTheme().pageTitle + `, you can rest easy, even if your vehicle lets you down.
     
Because we can cover up to 100% of your breakdown repair costs, so you don’t get caught with an unexpected bill.

Mechanical and electrical failures can happen, and do happen.
      
So get covered, and let `+ checkTheme().pageTitle + ` cushion any unforeseen blows with secure and reliable breakdown insurance.`,
    },
    {
      title: "Take Cover Against Mechanical and Electrical Car Failures",
      description: `Our vehicle warranty is there to keep you fully covered. 

 

That's why we take care of costs for both parts and labour in the event of any mechanical or electric faults. 

 

Remember, our vehicle warranty is much cheaper than the cost of repairs, so take advantage today.
     `,
    },
    {
      title: "Remove the stress, let us take care of your repair",
      description: `We can't predict the future, but ` + checkTheme().pageTitle + ` accounts for all possibilities, to give you the ultimate peace of mind in any given situation.

We know it's stressful when your vehicle lets you down, so we leave no stone unturned in making it as comfortable as possible for you in such an event.`,
    },

    {
      title: "Give Your Cover a Boost",
      description: `Say no to unwanted repair bills. 

 

Shout YES to `+ checkTheme().pageTitle + `'s instant warranty cover, which keeps you fully protected when your vehicle lets you down. 

 

For anything beyond wear and tear, we offer a premium package which includes your car's battery, sat-nav, in-car entertainment and more! 
`,
    },
  ],
  Excess: [
    {
      title: `Say No to Excess - Cover All Eventualities`,
      description: `Cover yourself in all situations with ` + checkTheme().pageTitle + `’s excess car insurance, designed to keep unnecessary bills away in the event of making a claim.

But what does excess insurance cover?

Put simply, whenever you have to make claim under your motor insurance policy, we will pay for any excess costs incurred, unless it exceeds your claims limit.

Want more details? Then please see below.`,
    },
  ],
  Wedding: [
    {
      title: ``,
      description: `Weddings and civil ceremonies are a special cause for celebration; a day you will remember for the rest of your life.We know they can cost a lot, both financially and emotionally. 

We also understand how stressful it can be if something doesn’t quite go how you planned it.

That’s why at `+ checkTheme().pageTitle + `, we don’t leave anything to chance, offering Wedding Insurance to give you the  peace of mind on your big day.`,
      quoteButton: "abc",
    },
    {
      title: `Do I Need Wedding Insurance?`,
      description: `Wedding insurance from ` + checkTheme().pageTitle + ` is there to offer financial protection should things go wrong before or on your wedding day. The last thing you want is something to go wrong on your big day, but if it does, don’t make it worse by being stressed and unnecessarily out of pocket. 

“What could possibly go wrong?” You may ask.

Well, without sending you into a cold sweat, here’s a few reasons why at `+ checkTheme().pageTitle + `, we expect the unexpected:

No-shows from vendors, venue closures, cancellations, sudden illness, extreme weather, damage, loss, theft, and of course, global pandemic outbreaks!

These covers are subject to the terms and conditions of the policy wording and an additional premium may need to be paid for some sections.

So don’t get cold feet when it comes to Wedding Insurance. Tie the knot with `+ checkTheme().pageTitle + ` to gift yourself peace of mind for your truly special occasion.`,
    },
    {
      title: `What Does Wedding Insurance Cover?`,
      description: `Protect your finances with Wedding Insurance from ` + checkTheme().pageTitle + `, featuring five ranges of cover, each with a choice indemnity limits, to let you choose the package that best suits you. 

Ensure your big day has financial protection with `+ checkTheme().pageTitle + `’s insurance packages including many things from cancellations to wedding attire, rings to cakes, flowers to transportation, and much more!`,
    },
  ],
};

export const ProgressBarSteps = [
  "Your details",
  "Vehicle details",

  "Available products",
  "The Legal Bit",
  "Complete",
];

export const reviews = [
  {
    review: `"I saved a lot of money on both my alloy and gap insurance compared to what my dealer quoted me, friendly, and fast service. I would definitely recommend ` + checkTheme().pageUrl + `."`,
    name: "- Mr Bronwick",
  },
  // {
  //   review:`"I saved a lot of money on both my alloy and gap insurance compared to what my dealer quoted me, friendly, and fast service. I would definitely recommend `+checkTheme().pageUrl+`."`,
  //   name:'- Mr Bronwick'
  // },
  // {
  //   review:`"I saved a lot of money on both my alloy and gap insurance compared to what my dealer quoted me, friendly, and fast service. I would definitely recommend `+checkTheme().pageUrl+`."`,
  //   name:'- Mr Bronwick'
  // },
];

export const mbiPageContent = [
  {
    origin: "MBI",
    coveredList: [
      {
        title: "Unexpected Costs:",
        description:
          "A used vehicle is more likely to suffer a mechanical failure than a brand-new vehicle and we do not buy any age of vehicle on the presumption it is going to break down. Any Mechanical faults, and their related costs, will come as an unexpected blow at any time, but a " + checkTheme().pageTitle + " warranty could help to cushion that blow.",
      },
      {
        title: "Cheaper than the Cost of a Repair:",
        description:
          "We may well have some type of insurance that we elect to pay for, and we may pay for that annually or on a monthly basis and yet we may never have to use it. If we do use that insurance at some point, we rest easy in the knowledge that the insurance will take care of the resulting costs. If your vehicle does suffer from a mechanical or electrical failure and you have a " + checkTheme().pageTitle + " warranty in place, you may well find that the cost of those repairs will be 100% covered by us, but please bear in mind that the total costs might exceed the cost of the " + checkTheme().pageTitle + " warranty you have paid for. Please look at the Claim Limit options available.",
      },
      {
        title: "Expenses other than an actual repair:",
        description:
          "Your vehicle may not be able to be repaired on the same day. Depending on the failure it may well be out of action for a few days or even weeks. " + checkTheme().pageTitle + "`s warranty will cover related costs beyond the repair, including car hire or overnight accommodation. Please see what is available under our “Additional Benefits” section in the terms and conditions.",
      },
    ],
    competition: [
      {
        title: "Wear and Tear:",
        description:
          "Wear and Tear cover is available as an upgrade and (if selected and the additional premium is paid), means that if a part breaks, or fails, due to the mileage or running of the vehicle, it is typically considered as failure due to “Wear & Tear” and it is the expected deterioration or performance of a part due to the cars age or mileage. Apart from burnt or worn-out clutch parts, all our warranties include cover for Wear and Tear, after the initial exclusion period.",
      },
      {
        title: "Boost:",
        description:
          "Boost cover is available as an upgrade on all our warranties and (if selected and the additional premium is paid), We would cover the Battery, Catalytic Convertor,  Satellite Navigation system, In-car Entertainment and Diesel Particulate Filter.",
      },
    ],
    mbiBackedInsurance: [
      {
        text: "FSCS Protected & Insurance backed by an A-rated Insurer.",
      },
      {
        text: "Cover starts on day one.",
      },
      {
        text: "Cover available for Car, Light Commercial Vehicle, Motorcycle, Moped, Motorhome or Caravan.",
      },
      {
        text: "Cover is for All mechanical and all electrical failure – called “Elite” on Car / LCV or “Gold” on Motorhome, Caravan, Motorcycle or Moped.",
      },
      {
        text: "Cover for wear and tear and Boost Cover are available as an upgrade for an additional premium.",
      },
      {
        text: "Partial or no service history prior to inception is not a problem.",
      },
      {
        text: "All Mechanical and Electrical cover for vehicles up to 10 years / 100,000 miles.",
      },
      {
        text: "No age or mileage limit once cover starts.",
      },
      {
        text: "30 days cooling off period – subject to no claim having been made.",
      },
      {
        text: "Cover available for 1, 2 or 3 years.",
      },
      {
        text: "Pay in full or over 10 months at 18.41% APR, with a small deposit.",
      },
      {
        text: "All claims settled directly to our approved repairers.",
      },
    ],
  },
];

export const cookiePageContent = [
  {
    title: "Definitions and Interpretation",
    subtitle:
      "In this Policy the following terms shall have the following meanings:",
    description: "",
    content: [
      {
        subtitle: "Data - ",
        description: `means collectively all information that you submit to the “` + checkTheme().pageTitle + `.com” via the Website. This definition shall, where applicable, incorporate the definitions provided in the Data Protection Act 1998;`,
      },
      {
        subtitle: "Cookie - ",
        description: `means a small text file placed on your computer by this Website when you visit certain parts of the Website and/or when you use certain features of the Website. Details of the cookies used by this Website are set out in Clause 12;`,
      },
      {
        subtitle: checkTheme().pageTitle + ".com - ",
        description: ` means the company “` + checkTheme().pageTitle + `.com” who are a trading style of Rest Ashored Ltd, which is authorised and regulated by the Financial Conduct Authority (FCA No 732530).`,
      },
      {
        subtitle: "UK and EU Cookie Law - ",
        description: `means the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011;`,
      },
      {
        subtitle: "User, You - ",
        description: ` means any third party that accesses the Website and is not employed by “` + checkTheme().pageTitle + `.com” and acting in the course of their employment;`,
      },
      {
        subtitle: "Website - ",
        description: `means the website that you are currently using (www.` + checkTheme().pageUrl + `) and any sub-domains of this site (e.g. subdomain.www.` + checkTheme().pageUrl + `) unless expressly excluded by their own terms and conditions.`,
      },
      {
        subtitle: "We,Us - ",
        description: checkTheme().pageTitle + `.com`,
      },
      {
        subtitle: "Data Controller - ",
        description: `Rest Ashored Limited`,
      },
    ],
  },
  {
    title: "Scope of this Policy",
    subtitle:
      "This Policy applies only to the actions of “" + checkTheme().pageTitle + ".com” and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites.",
    description: ``,
    content: [],
  },
  {
    title: "Data Collected",
    subtitle: `
     Without limitation, any of the following Data may be collected by this Website from time to time:
     `,
    description: `We are the Data Controller for the data You provide to Us. We need to use Your data in order to arrange Your insurance and associated products.
You are obliged to provide information without which We will be unable to provide a service to You. Any personal information provided by You may be held by the Insurer in relation to Your insurance cover. It may be used by Our relevant staff in making a decision concerning Your insurance and for the purpose of servicing Your cover and administering claims.


Information may be passed to loss adjusters, solicitors, reinsurers or other service providers for these purposes. We may obtain information about You from credit reference agencies, fraud prevention agencies and others to check Your credit status and identity. The agencies will record Our enquiries, which may be seen by other companies who make their own credit enquiries. If You provide false or inaccurate information and We suspect fraud, We will record this.`,
    content: [
      {
        subtitle: "",
        description: `name`,
      },
      {
        subtitle: "",
        description: `date of birth`,
      },
      {
        subtitle: "",
        description: `vehicle details`,
      },
      {
        subtitle: "",
        description: `job title/study level`,
      },
      {
        subtitle: "",
        description: `Employer`,
      },
      {
        subtitle: "",
        description: `contact information such as email addresses and telephone numbers`,
      },
      {
        subtitle: "",
        description: `demographic information such as post code, preferences and interests`,
      },
      {
        subtitle: "",
        description: `financial information such as credit / debit card numbers`,
      },
      {
        subtitle: "",
        description: `IP address (automatically collected)`,
      },
      {
        subtitle: "",
        description: `web browser type and version (automatically collected)`,
      },
      {
        subtitle: "",
        description: `operating system (automatically collected)`,
      },
      {
        subtitle: "",
        description: `a list of URLs starting with a referring site, your activity on this Website, and the site you exit to (automatically collected);`,
      },
      {
        subtitle: "",
        description: `When you obtain a quote but do not purchase a policy we will store your information and may contact you to`,
      },
    ],
  },
  {
    title: "We and other organisations may use these records to:",
    subtitle: `
    
     `,
    description: ` We process all data in the UK but where We need to disclose data to parties outside the European Economic Area (EEA) We will take reasonable steps to ensure the privacy of Your data. In order to protect Our legal position, We will retain Your data for a minimum of 7 years. We have a Data Protection regime in place to oversee the effective and secure processing of Your data. Under GDPR legislation, You can ask Us for a copy of the data We hold, have it corrected, sent to a third party or deleted (subject to Our need to hold data for legal reasons). We will not make Your personal details available to any companies to use for their own marketing purposes. If You wish to complain about how We have handled Your data, You can contact Us and We will investigate the matter. If You are not satisfied with Our response or believe We are processing Your data incorrectly You can complain to the Information
Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, Cheshire SK9 5AF Tel: 0303 123 1113.`,
    content: [
      {
        subtitle: "a.",
        description: ` Help make decisions on insurance proposals and insurance claims, for You and members of Your household.`,
      },
      {
        subtitle: "b.",
        description: `Trace debtors, recover debt, prevent fraud, and manage Your insurance policies`,
      },
      {
        subtitle: "c. ",
        description: `Check Your identity to prevent money laundering, unless You furnish Us with satisfactory proof of identity.`,
      },
    ],
  },
  {
    title: "Third Party Websites and Services",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "",
        description: `“` + checkTheme().pageTitle + `.com” may, from time to time, employ the services of other parties for dealing with matters that may include, but are not limited to, payment processing, delivery of purchased items, search engine facilities, advertising and marketing. The providers of such services have access to certain personal Data provided by Users of this Website.`,
      },
      {
        subtitle: "",
        description: `Any Data used by such parties is used only to the extent required by them to perform the services that “` + checkTheme().pageTitle + `.com” requests. Any use for other purposes is strictly prohibited. Furthermore, any Data that is processed by third parties shall be processed within the terms of this Policy and in accordance with the Data Protection Act 1998.`,
      },
    ],
  },
  {
    title: "Links to Other Websites",
    subtitle:
      "This Website may, from time to time, provide links to other websites. “" + checkTheme().pageTitle + ".com” has no control over such websites and is in no way responsible for the content thereof. This Policy does not extend to your use of such websites. Users are advised to read the privacy policy or statement of other websites prior to using them.",
    content: [],
  },

  {
    title: "Changes of Business Ownership and Control",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "",
        description: `“` + checkTheme().pageTitle + `.com” may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of control of all or part of “` + checkTheme().pageTitle + `.com”. Data provided by Users will, where it is relevant to any part of our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Policy, be permitted to use the Data for the purposes for which it was originally supplied to us.`,
      },
      {
        subtitle: "",
        description: `In the event that any Data submitted by Users is to be transferred in such a manner, you will not be contacted in advance and informed of the changes.`,
      },
    ],
  },

  {
    title: "Your Right to Withhold Information",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: ``,
        description: `You may access certain areas of the Website without providing any Data at all. However, to use all features and functions available on the Website you may be required to submit certain Data.`,
      },
      {
        subtitle: ``,
        description: `You may restrict your internet browser’s use of Cookies. For more information see Clause 12.`,
      },
    ],
  },
  {
    title: " Accessing your own Data",
    subtitle:
      "You have the right to ask for a copy of any of your personal Data held by “" + checkTheme().pageTitle + ".com” (where such data is held) by contacting us in writing at " + checkTheme().pageTitle + ".com, 15 Thameside, Henley-On-Thames, RG9 1BH.",
    description: "",
    content: [],
  },
  {
    title: "Security",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "",
        description: `Data security is of great importance to “` + checkTheme().pageTitle + `.com” and to protect your Data we have put in place suitable physical, electronic and managerial procedures to safeguard and secure Data collected via this Website.`,
      },
    ],
  },
  {
    title: "Cookies",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "",
        description: `This Website may place and access certain Cookies on your computer. “` + checkTheme().pageTitle + `.com” uses Cookies to improve your experience of using the Website and to improve our range of products and services. “` + checkTheme().pageTitle + `.com” has carefully chosen these Cookies and has taken steps to ensure that your privacy is protected and respected at all times.`,
      },
      {
        subtitle: "",
        description: `All Cookies used by this Website are used in accordance with current UK and EU Cookie Law.`,
      },
      {
        subtitle: "",
        description: `[Certain features of the Website depend upon Cookies to function. UK and EU Cookie Law deems these Cookies to be strictly necessary]. These Cookies are shown below. Your consent will not be sought to place these Cookies. You may still block these cookies by changing your internet browser’s settings.`,
      },
      {
        subtitle: "",
        description: `You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser.`,
      },
      {
        subtitle: "",
        description: ` You can choose to delete Cookies at any time however you may lose any information that enables you to access the Website more quickly and efficiently including, but not limited to, personalisation settings.`,
      },
      {
        subtitle: "",
        description: `It is recommended that you ensure that your internet browser is up-to-date and that you consult the help and guidance provided by the developer of your internet browser if you are unsure about adjusting your privacy settings.`,
      },
    ],
  },
  {
    title: "Changes to this Policy",
    subtitle:
      "“" + checkTheme().pageTitle + ".com” reserves the right to change this Policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted the terms of the Policy on your first use of the Website following the alterations.",
    description: "",
    content: [],
  },
];

export const secondaryPagesHeaderImages = [
  {
    origin: "Return to Invoice",
    image: gap26,
  },
  {
    origin: "Finance Gap",
    image: gap26,
  },
  {
    origin: "Alloy Wheel",
    image: alloy26,
  },

  {
    origin: "Tyre",
    image: tyre26,
  },
  {
    origin: "MBI",
    image: mbi26,
  },
  {
    origin: "Cosmetic Repair",
    image: cosmetic26,
  },

  {
    origin: "Excess",
    image: mbi26,
  },
];

export const fullParagraphContent = {
  "About Us": [
    {
      title: `Decades of Experience `,
      description: `Just think how good you’d be at something if you’d been doing it for over 20 years? 
  Our team of car-lovers have been saving people money and time for decades, using 
  their experience and knowledge across all kinds of insurance markets in the UK and 
  across Europe. 
  That’s why we know how to get the best deal for you and your vehicle.
  We’ve used all that knowledge to create a helpful range of policies to keep you fully 
  covered.`,
      image: aboutUs2,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    },
    {
      title: `We Believe in Giving Back`,
      description: `Responsibility is an important word to us here at ` + checkTheme().pageTitle + `. 
  Not only are we responsible for your car’s every need, but we do our part in being 
  responsible for nurturing our planet. 
  That’s why for every policy sold, we plant one tree. 
  If you think about it, that’s A LOT of trees. 
  Especially when you consider our policy prices. 
  Want to help plant a tree today? 
  `,
      image: giving,
      imagePosition: "right",
      button: "abc,"
    },
  ],

  "Alloy Wheel": [
    {
      title: "Protect yourself and your vehicle against unforeseen costs",
      description: "At " + checkTheme().pageTitle + ", we cover ALL accidental damage repair costs, giving you the ultimate peace of mind when it comes to your vehicle’s alloy wheels.",
      image: alloy_paragraph_image,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    }
  ],

  "Homepage": [
    {
      title: "Protect yourself and your vehicle against unforeseen costs",
      description: "At " + checkTheme().pageTitle + ", we are here to offer you the ultimate peace of mind when it comes to your vehicle.",
      image: car_ins_homepage,
      imagePosition: "left",
      button: "Get A Quote",
    },
    {
      title: "Clear answers to your questions",
      description: "We save you money, we save you time, but we also save you the hassle. Don’t fret about anything you’re unsure of. If you have a question, anything at all, we have a dedicated team of customer representatives on hand to answer them.",
      image: car_ins_par_homepage,
      imagePosition: "right",
      phone: "0161 505 1420",
    }
  ],
  "TreePlantedPage": [
    {
      title: `Why are trees important?`,
      description: `Trees help clean the air we breathe, filter the water we drink, and provide habitat to over 80% of the world’s terrestrial biodiversity. Forests provide jobs to over 1.6 billion people, absorb harmful carbon from the atmosphere, and are key ingredients in 25% of all medicines. Have you ever taken an Aspirin? It comes from the bark of a tree!`,
      image: importanceOfTrees,
      imagePosition: "left",
    },
    {
      title: `Air`,
      description: `Trees are like the vacuums of our planet. Through their leaves and bark, they absorb harmful pollutants and release clean oxygen for us to breathe. In urban environments, trees absorb pollutant gases like nitrogen oxides, ozone, and carbon monoxide, and sweep up particles like dust and smoke. Increasing levels of carbon dioxide caused by deforestation and fossil fuel combustion trap heat in the atmosphere. Healthy, strong trees act as carbon sinks, absorbing atmospheric carbon dioxide and reducing the effects of climate change.`,
      image: air,
      imagePosition: "right",
    },
    {
      title: `Water`,
      description: `Trees play a key role in capturing rainwater and reducing the risk of natural disasters like floods and landslides. Their intricate root systems act like filters, removing pollutants and slowing down the water’s absorption into the soil. This process prevents harmful waterslide erosion and reduces the risk of oversaturation and flooding. According to the UN Food and Agriculture Association, a mature evergreen tree can intercept more than 15,000 litres of water every year.`,
      image: water,
      imagePosition: "left",
      halfPinkPig: "yes",
    },
    {
      title: `Biodiversity`,
      description: `A single tree can be home to hundreds of species of insect, fungi, moss, mammals, and plants. Depending on the kind of food and shelter they need, different forest animals require different types of habitat. Without trees, forest creatures would have nowhere to call home.`,
      image: biodiversity,
      imagePosition: "right",
    },
    {
      title: `Social impact`,
      description: `From arborists to loggers and researchers, the job opportunities provided by the forestry industry are endless. We don’t just rely on trees for work, though. Sustainable tree farming provides timber to build homes and shelters, and wood to burn for cooking and heating. Food-producing trees provide fruit, nuts, berries, and leaves for consumption by both humans and animals, and pack a powerful nutritional punch.`,
      image: socialImpact,
      imagePosition: "left",
    },
    {
      title: `Health`,
      description: `Did you know that hospital patients with rooms overlooking trees recover faster than those without the same view? It’s impossible to ignore that feeling of elation you get while walking through a calm, quiet forest. Trees help reduce stress and anxiety, and allow us to reconnect with nature. In addition, shade provided by tree coverage helps protect our skin from the ever-increasing harshness of the sun.`,
      image: health,
      imagePosition: "right",
      halfBluePig: "yes",
    },
    {
      title: `Climate`,
      description: `Trees help cool the planet by sucking in and storing harmful greenhouse gases like carbon dioxide into their trunks, branches, and leaves — and releasing oxygen back into the atmosphere. In cities, trees can reduce ambient temperatures by up to 8° Celsius. With more than 50% of the world’s population living in cities — a number expected to increase to 66% by the year 2050 — pollution and overheating are becoming a real threat. Fortunately, a mature tree can absorb an average of 48 lbs of carbon dioxide per year, making cities a healthier, safer place to live.`,
      image: climate,
      imagePosition: "left",
    },
  ],
  "Tyre": [
    {
      title: `Protect against tyre repair costs`,
      description: `Ease the pain of punctures and cover the cost of repair to your damaged tyres with ` + checkTheme().pageTitle + `’s insurance.`,
      image: tyreInsurance2,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    }
  ],
  "Cosmetic Repair": [
    {
      title: `Great-Value SMART Repair Insurance`,
      description: `Insurance for Small Medium Area Repair Technology (or SMART Repair) can be very expensive, especially when purchased from your local car dealer. \n
      That’s where `+ checkTheme().pageTitle + ` comes in, like our name suggests, to help you cut costs and offer you vehicle repair by one of our authorised specialists, helping to keep your car in showroom condition.`,
      image: cosmeticInsurance3,
      imagePosition: "left",
      pig: "yes",
    }
  ],
  "Excess": [
    {
      title: `What does excess insurance cover?`,
      description: `Put simply, whenever you have to make claim under your motor insurance policy, we will pay for any excess costs incurred, unless it exceeds your claims limit.`,
      image: excessInsurance3,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    }
  ],
  "Finance Gap": [
    {
      title: `Protect against depreciation`,
      description: `As you will know, the moment a vehicle is driven out off the showroom, its value begins to drop like a stone. In fact, the average vehicle is predicted to depreciate by up to 50% within the first three years alone.`,
      image: gapInsurance3,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    },
    {
      title: `Comprehensive cover in the event of a write-off`,
      description: `The amount that you are offered in settlement can be, and often is, thousands of pounds less than the original amount that you paid. ` + checkTheme().pageTitle + ` provides a solution to this problem, offering comprehensive cover which significantly boosts your payment in the event of a write-off.`,
      image: newImgwebp,
      imagePosition: "right",
    }
  ],
  "MBI": [
    {
      title: `Cover against mechanical & electrical failures`,
      description: `Our vehicle warranty is there to keep you fully covered. That’s why we take care of costs for both parts and labour in the event of any mechanical or electric faults. Remember, our vehicle warranty is much cheaper than the cost of repairs, so take advantage today.`,
      image: vehicleWarranty3,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    },
    {
      title: `Remove the stress, let us take care of your repair`,
      description: `We can’t predict the future, but ` + checkTheme().pageTitle + ` accounts for all possibilities, to give you the ultimate peace of mind in any given situation. We know it’s stressful when your vehicle lets you down, so we leave no stone unturned in making it as comfortable as possible for you in such an event.`,
      image: vehicleWarranty4,
      imagePosition: "right",
    }
  ],
  "Wedding": [
    {
      title: `Do I Need Wedding Insurance?`,
      description: `Wedding insurance from ` + checkTheme().pageTitle + ` is there to keep you protected. The last thing you want is something to go wrong on your big day, but if it does, don’t make it worse by being stressed and unnecessarily out of pocket.`,
      image: weddingInsurance3,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    }
  ],
}
// export const treePlantedPage = [
//   {
//     title: `Why are trees important?`,
//     description: `Trees help clean the air we breathe, filter the water we drink, and provide habitat to over 80% of the world’s terrestrial biodiversity. Forests provide jobs to over 1.6 billion people, absorb harmful carbon from the atmosphere, and are key ingredients in 25% of all medicines. Have you ever taken an Aspirin? It comes from the bark of a tree!`,
//     image: importanceOfTrees,
//   },
//   {
//     title: `Air`,
//     description: `Trees are like the vacuums of our planet. Through their leaves and bark, they absorb harmful pollutants and release clean oxygen for us to breathe. In urban environments, trees absorb pollutant gases like nitrogen oxides, ozone, and carbon monoxide, and sweep up particles like dust and smoke. Increasing levels of carbon dioxide caused by deforestation and fossil fuel combustion trap heat in the atmosphere. Healthy, strong trees act as carbon sinks, absorbing atmospheric carbon dioxide and reducing the effects of climate change.`,
//     image: air,
//   },
//   {
//     title: `Water`,
//     description: `Trees play a key role in capturing rainwater and reducing the risk of natural disasters like floods and landslides. Their intricate root systems act like filters, removing pollutants and slowing down the water’s absorption into the soil. This process prevents harmful waterslide erosion and reduces the risk of oversaturation and flooding. According to the UN Food and Agriculture Association, a mature evergreen tree can intercept more than 15,000 litres of water every year.`,
//     image: water,
//   },
//   {
//     title: `Biodiversity`,
//     description: `A single tree can be home to hundreds of species of insect, fungi, moss, mammals, and plants. Depending on the kind of food and shelter they need, different forest animals require different types of habitat. Without trees, forest creatures would have nowhere to call home. Young, Open Forests: These forests occur as a result of fires or logging. Shrubs, grasses, and young trees attract animals like black bears, the American goldfinch, and bluebirds in North America. Middle-Aged Forests: In middle-aged forests, taller trees begin to outgrow weaker trees and vegetation. An open canopy allows for the growth of ground vegetation prefered by animals like salamanders, elk, and tree frogs.
// Older Forests: With large trees, a complex canopy, and a highly developed understory of vegetation, old forests provide habitat for an array of animals, including bats, squirrels, and many birds`,
//     image: biodiversity,
//   },
//   {
//     title: `Social impact`,
//     description: `From arborists to loggers and researchers, the job opportunities provided by the forestry industry are endless. We don’t just rely on trees for work, though. Sustainable tree farming provides timber to build homes and shelters, and wood to burn for cooking and heating. Food-producing trees provide fruit, nuts, berries, and leaves for consumption by both humans and animals, and pack a powerful nutritional punch.`,
//     image: socialImpact,
//   },
//   {
//     title: `Health`,
//     description: `Did you know that hospital patients with rooms overlooking trees recover faster than those without the same view? It’s impossible to ignore that feeling of elation you get while walking through a calm, quiet forest. Trees help reduce stress and anxiety, and allow us to reconnect with nature. In addition, shade provided by tree coverage helps protect our skin from the ever-increasing harshness of the sun.`,
//     image: health,
//   },
//   {
//     title: `Climate`,
//     description: `Trees help cool the planet by sucking in and storing harmful greenhouse gases like carbon dioxide into their trunks, branches, and leaves — and releasing oxygen back into the atmosphere. In cities, trees can reduce ambient temperatures by up to 8° Celsius. With more than 50% of the world’s population living in cities — a number expected to increase to 66% by the year 2050 — pollution and overheating are becoming a real threat. Fortunately, a mature tree can absorb an average of 48 lbs of carbon dioxide per year, making cities a healthier, safer place to live.`,
//     image: climate,
//   },
// ];

export const treePlantedHeader = {
  description:
    "With every Policy purchased from " + checkTheme().pageTitle + " a Tree is Planted.",
  background: forestHeader,
  checks: [
    {
      check: "Save Money",
    },
    {
      check: "Plant a tree",
    },
    {
      check: "Heal the planet",
    },
  ],
  imgwebp: checkTheme().imgTree,
  imgpng: checkTheme().imgTreepng,
};

export const weddingHeader = {
  description:
    "Giving peace of mind on your big day.",
  checks: [
    {
      check: "Protect your finances",
    },
    {
      check: "Choose the package that best suits you",
    },
    {
      check: "Five ranges of cover",
    },
    {
      check: "Complete wedding day cover",
    },
  ],
  imgwebp: checkTheme().imgWedding,
  imgpng: checkTheme().imgWeddingpng,
};

export const aboutHeader = {
  description: checkTheme().pageTitle + "’s aim is to save you both time and money on your insurance.",
  checks: [
    {
      check: "Providing products to over £180 million worth of vehicles across the UK",
    },
    {
      check: "A tree planted with every purchase",
    },
    {
      check: "Decades of experience & FSCS protected",
    },
  ],
  imgwebp: checkTheme().imgAboutUs,
  imgpng: checkTheme().imgAboutUspng,
}

export const weddingInsuranceTiles = {
  title: `What Weddings can we cover?`,
  description: `The ` + checkTheme().pageTitle + ` Wedding Insurance policies are available for any ceremony which 
creates a contract of marriage which is legally enforceable within the United Kingdom, 
including ceremonies of Civil partnership, or, for the purposes of this insurance, a 
similar single, specific event/ceremony/blessing arranged to celebrate a marriage or 
renewal of vows`,
  tiles: [
    {
      title: "Cancellation Cover",
      description: `All of our Wedding Insurance policies will provide you with cancellation and rearrangement cover should the unexpected happen, causing you to cancel or rearrange your wedding (for an insured reason) Please see page 3 of the terms and conditions for details. 

With 5 different policy levels, you can purchase a policy to suit your own needs to cover the ceremony you are planning.

Below is a list of our current policy levels and how much cancellation cover they provide

**Silver**: £6,000 Cancellation

**Gold**: £10,000 Cancellation

**Amber**: £15,000 Cancellation

**Platinum**: £20,000 Cancellation

**Diamond**: £30,000 Cancellation with an option to upgrade to £50,000`
    },
    {
      title: "Re-arrangement Cover",
      description: `In the event of the cancellation of your planned wedding, it may be that you wish to rearrange an alternative ceremony. 
All `+ checkTheme().pageTitle + ` Wedding include protection for the additional costs necessarily incurred in rearranging the Wedding and/or Wedding reception and/or Wedding services to a similar standard to the amount originally budgeted. 

This benefit is only available in the event of a valid claim for the cancellation of the Wedding, Wedding reception or Wedding services as covered under our cancellation benefit.

In respect of rearrangement, all additional costs and expenses must be notified to the claims handlers and agreed by them in advance of the rearranged wedding.

See page 3 of the terms and conditions for the claim limits applicable.`
    },
    {
      title: "Ceremonial Attire",
      description: `All ` + checkTheme().pageTitle + ` Wedding Insurance policies offer cover to protect you against the 
accidental complete loss of or severe damage to the ceremonial attire which renders 
the items unwearable and where the purchase or hire of alternatives is not possible.
Cover is for clothing and accessories of the Marrying couple, male and female 
attendants and the parents of the Marrying couple, whether hired or owned, excluding 
ceremonial swords.
The Claim Limit varies depending on the level of cover selected and ranges from 
£3,000 to £10,000. Please see page 3 of the full terms and conditions for exact details`
    },
    {
      title: "Wedding Gifts",
      description: `With our Wedding Gifts Coverage, included in all of our policies, your gifts are covered for up to 7 days before your big day and for 24 hours afterwards. The aggregate claim limit varies depending on the level of cover selected and ranges from £3,000 to £10,000 and the individual cover per item, ranging from £300 to a maximum of £1,000. The individual claim limit on cash or vouchers ranges from £200 to £1,000.`
    },
    {
      title: "Rings, Flowers, Gifts and Cake",
      description: `**Cake**- You may be surprised to hear that the average couple spends around £300 on 
the cake for their big day which in itself is a big purchase, even if it's a relatively small 
proportion of your wedding spend. All the `+ checkTheme().pageTitle + ` wedding insurance policies 
include cover so that you can benefit between £500 and £2,000* worth of protection 
for your Wedding Cake, should the unexpected happen.

**Rings**- Cover for Wedding Rings is included as standard on all wedding insurance 
policies and can provide between £500 and £2,000* per item (ring).

With the amount of time and effort you and your partner will put into finding the right 
rings for yourselves, and with the amount of money you are likely to spend on the 
perfect rings, it makes sense to insure these should the unexpected happen.

**Attendant Gifts** – Any gift that you have purchased for your wedding attendants will
have been carefully chosen by you and so it will be good to know that you will be 
covered against loss or accidental damage for the 7 days prior to and 24 hours after 
the ceremony.

**Flowers**- No wedding would be complete without stunning flower arrangements and 
eye-catching bouquets!

What might surprise you is the cost of these flowers, with many couples ending up 
spending more on their wedding flowers than they had originally budgeted for 
especially if certain exotic flowers are arranged by a florist. That's why all our Wedding 
Insurance policies include wedding flowers cover as standard.

With up to £2,000* of cover available for Wedding Flowers you can be safe in the 
knowledge that you are protected should your flowers get lost or accidentally damaged 
in the 36 hours prior to the ceremony or during the ceremony or reception themselves.`
    },
    {
      title: "Wedding Cars & Transport",
      description: `All ` + checkTheme().pageTitle + ` Wedding Insurance policies offer financial protection for unforeseen 
additional costs to arrange alternative transport, if the contracted private hire 
provider, with whom the wedding transport arrangements have been made fails to 
meet its/their contractual obligation(s) following non-appearance, breakdown or 
accident`
    },
    {
      title: "Photography and Video",
      description: `There is a long list of things that can go wrong when it comes to wedding photos 
and wedding videos. Whether it's the Professional Photographer who was booked for 
your wedding day not showing up, or the films being lost or damaged before the 
final prints are produced. All of our Wedding Insurance policies include coverage for 
Photography and Video to cover the costs incurred should you need to take/re-take 
your Wedding Photos!

All `+ checkTheme().pageTitle + ` Wedding Insurance policies offer between £3,000* and £10,000*
of Photography and Video cover, depending on the claim limit selected, so you can 
rest assured that you have ample cover should the unexpected happen!`
    },
    {
      title: "Financial Failure",
      description: `It's fair to say most couples worry about some of their wedding service suppliers 
'going under' before their big day.

Whether that's the caterers, the photographer, or any other wedding supplier, in this 
current economic environment nothing is certain. This can obviously have huge 
implications for your wedding especially if you are unable to recoup any deposits you 
may have paid.

If this does occur then, in line with the policy wording, you will be covered for any 
irrecoverable deposits and any additional costs you face in arranging an alternative, 
equivalent service between £3,000* and £15,000*. 

Cover for Financial Failure is included as standard on all Wedding Insurance policies 
because we want you to have the wedding you have always dreamed of and aren't 
left out of pocket because of something out of your control.`
    },
    {
      title: "Personal Accident",
      description: `All ` + checkTheme().pageTitle + ` Wedding Insurance policies offer cover for the marrying couple 
against personal accident, which includes the loss of sight, a limb or any permanent
disablement within 12 months of a covered accident.

The claim limit varies between £5,000 and £20,000 depending on the level of cover 
selected.`
    },
    {
      title: "Professional Counselling",
      description: `All ` + checkTheme().pageTitle + ` Wedding Insurance policies offer cover in respect of professional 
counselling that is recommended by a general practitioner for either of the marrying 
couple as a result of the unforeseen, unavoidable and permanent cancellation of the 
wedding due to the death of either of the marrying couple or either of the marrying 
couple electing not to continue with the marriage.

The claim limit is £1,000 irrespective of the level of cover selected.
`
    },
    {
      title: "Legal Expenses",
      description: `All ` + checkTheme().pageTitle + ` Wedding Insurance policies offer cover for any legal costs and 
expenses incurred by you, in the pursuit of legal proceedings by you or your 
personal representative(s) for compensation and/or damages arising from or out of 
your injury or death. 

The claim limit varies between £5,000 and £20,000 depending on the level of cover 
selected.
`
    },
    {
      title: "Public Liability",
      description: `**Section 11** - Marrying couple only - Are looking for Public Liability Insurance for 
your Wedding? Then you are in the right place as all the `+ checkTheme().pageTitle + ` Wedding 
Insurance policies include £2 million of Public Liability cover for the Marrying Couple 
as standard. What's more, we offer 2 optional Public Liability upgrades too.

**Section 12** - Upgrade to include guests and £2 million cover limit - If you 
need your Wedding Guests to have Public Liability cover too then we offer a policy 
upgrade where you can extend this element of cover to include them too. To do 
this, you will need to pay the appropriate additional premium and then your Guests 
will also benefit from Public Liability cover up to £2 million.

**Section 13** - Upgrade to include guests and £5 million cover limit - If £2 
million Public Liability cover is not enough, maybe because you're getting married in 
a stately home or National Trust property (who often require a higher level of 
Liability cover), then the `+ checkTheme().pageTitle + ` Wedding Insurance policies also offer a £5 
million Public Liability upgrade for additional peace of mind on your big day. This 
increased level of cover is only available if you upgrade to include your wedding 
guests`
    },
    {
      title: "Marquee & Event Equipment",
      description: `If you are holding your Wedding ceremony or reception in a standalone marquee, then 
it makes sense to cover this within your Wedding Insurance policy, as you would be 
liable to the marquee hire company for any loss or damage sustained during the period 
of hire.

The `+ checkTheme().pageTitle + ` Wedding Insurance offers an Optional upgrade to include
Marquee & Mobile W.C. unit and Event Equipment extension that will cover you for 
the loss or damage of your hired marquee during the period of hire.

The additional premium payable will depend on the cost of the marquee itself. For 
instance, if the marquee is worth £20,000 your additional premium would be less than 
if the marquee was worth £50,000.`
    },
    {
      title: "Ceremonial Swords",
      description: `Ceremonial Swords can play a big part in couples' weddings or civil partnerships, 
typically those with in the armed forces but also in some religious ceremonies. 

Military Weddings have numerous traditions, including involving Ceremonial Swords 
either in a guard of honour for the Marrying Couple or even in the cutting of the cake 
and these swords can be incredibly expensive and so it is wise to insure them as well 
as the rest of your big day.

Should you choose to upgrade and pay the additional premium, you will benefit 
from up to £20,000 worth of cover should you lose or damage any borrowed or hired 
Ceremonial Swords (and accompanying regalia) within the 7 days leading up to the 
wedding or for the 48 hours after the wedding.`
    }, {
      title: "Coronavirus Cover",
      description: `After everything that has happened across the world over the last 2 years, you 
might decide to take the upgrade to add coronavirus cover.

If you do you upgrade and pay the additional premium you will be covered up to the 
claim limit selected, for cancellation due to the death or sickness of the Marrying 
couple or a Relative, due to contracting Coronavirus, within 10 days of the Wedding 
date, which would make the continuance of the Wedding inappropriate. This is 
subject to the Marrying couple or Relative all being fully vaccinated (including 
booster vaccination where appropriate) in accordance with government guidelines.

You will also be covered for the additional re-arrangement costs, if applicable. `
    }

  ],
};

//events procedure

export const eventsProgressBar = [
  "Your details",
  "Event Details",
  "Available Products",
  "The Legal Bit",
  "Complete",
];

// export const weddingFaq = [
//   {
//     question: `Is wedding insurance paid monthly?`,
//     answer: `No, wedding insurance is just a one-off payment, showing what excellent value for money it is.`,
//   },
//   {
//     question: `When should we take out wedding insurance?`,
//     answer: `Ideally, you should take it out as soon as you have paid any deposits or signed a contract that means you will lose money if you are forced to change your plans. You can arrange our insurance at any time up to 24 months before the big day.`,
//   },
//   {
//     question: `Who can buy wedding insurance?`,
//     answer: `Anyone can buy a wedding insurance policy, for instance parents of the bride or groom, but the policy must be in the name of the two people who are getting married as it is their wedding being covered.`,
//   },
//   {
//     question: `I have already paid a deposit. Will this be covered?`,
//     answer: `Yes - provided you are not aware of any particular circumstances that may give rise to a claim when you take out the policy.`,
//   },
//   {
//     question: `Does wedding insurance cover relative's money?`,
//     answer: `Our wedding insurance will cover money paid by relatives of the couple, providing they have a demonstrable financial interest, such as a receipt or booking confirmation.

//       All claims will have to be submitted by the insured couple and then the settlement passed on to the person who paid.
//       `,
//   },
//   {
//     question: `We are getting married in a small civil ceremony with a larger church blessing & reception 2 weeks later. Are both dates covered?`,
//     answer: `Both dates will be covered provided they are not more than 21 days apart. If your civil marriage and blessing/reception are more than 21 days apart then you will need to take out two separate policies, one for each date. The two events must be less than 21 days apart to be covered under the same policy.`,
//   },
//   {
//     question: `My fiancé lives abroad. Can we take out a policy?`,
//     answer: `Yes - provided one of you is normally resident here in the UK you can take out a policy.`,
//   },
//   {
//     question: `My fiancé is in the Armed Forces. Are we covered if he is unexpectedly posted abroad?`,
//     answer: `Yes - provided that there was no notice of possible cancellation of leave at the time the policy was taken out.`,
//   },
//   {
//     question: `What about if one of us decides not to go ahead with the marriage after all?`,
//     answer: `This is known as "disinclination to proceed" and is not an insured reason for cancellation, sadly. However, you may be able to claim for cost of professional counselling under Section 9 of the policy.`,
//   },
//   {
//     question: `Is family bereavement covered by wedding insurance?`,
//     answer: `Cancellation or rearrangement due to family bereavement is covered under section 1 of our wedding insurance policy if it makes going ahead with the wedding inappropriate. However, you must answer the medical declaration for the policy in respect of all people on whom your wedding plans depend before you purchase the policy. If you answer yes to any of the medical questions with regard to a relative, you will not be covered in relation to that medical condition. You will of course be covered for anything unrelated to that relative's condition.If you are in doubt please contact us on 0161 505 1420, before you purchase the policy.`,
//   },
//   {
//     question: `What happens if the professional wedding photographs fail because the film or digital storage media is faulty and there are no copies available?`,
//     answer: `Then the reasonable costs of taking replacement formal photographs of the couple and the immediate Wedding party can be claimed.`,
//   },
//   {
//     question: `I have an existing medical condition. Can this be covered?`,
//     answer: `Most existing medical conditions are insured automatically, both for the Couple and also their close relatives. There is no cover, however, for Pre-existing medical conditions that have resulted in a terminal prognosis or where the condition is awaiting treatment or investigation at a hospital or you are awaiting the results of tests or investigations, unless you have declared details to us and we have agreed to insure them. You can call us to discuss a medical condition on 0161 505 1420.`,
//   },
//   {
//     question: `What happens if one of the suppliers goes bust?`,
//     answer: `Then you may claim for any irrecoverable deposits that you may have paid and also for the necessary additional costs in arranging an equivalent alternative. A definition of wedding service suppliers is included in the policy wording.`,
//   },
//   {
//     question: `Our wedding venue is flooded just before the big day. Are we covered?`,
//     answer: `Then you may claim for any irrecoverable deposits that you may have paid and also for the necessary additional costs in arranging an equivalent alternative. A definition of wedding service suppliers is included in the policy wording.`,
//   },
//   {
//     question: `We are hiring a marquee and will need to insure it. Is this included or can it be added to the policy`,
//     answer: `There is an optional extension that you can take to add this cover for up to £20,000 or £50,000, subject to payment of the appropriate additional premium.`,
//   },
//   {
//     question: `Our venue requires us to provide insurance in case any of us or the guests causes accidental damage during the reception. Is this included?`,
//     answer: `The marrying couple’s Public liability is included as standard up to £2m. There is an optional extension available to add cover for the liabilities of your wedding party up to £2m in total. This can be further increased to be £5m with the additional extension.`,
//   },
//   {
//     question: `Is my honeymoon or overseas wedding trip insured on this policy?`,
//     answer: `No - you will need to take out separate travel insurance for your honeymoon or overseas wedding trip, which would normally include cancellation cover if you are not able to travel as planned.`,
//   },
//   {
//     question: `Does the wedding insurance policy have an excess?`,
//     answer: `Yes, there is an excess of £100 applicable under all sections of the policy, except for the Public Liability sections (sections 12, 13 & 14) which have an excess of £250.`,
//   },
// ];



//vehicle checkout V2 info


export const insuranceOptions = [
  {
    title: 'Alloy Insurance',
    origin: 'Alloy Wheel',
    shortOrigin: 'alloy',
    image: '../assets/alloy2x.webp'
  },
  {
    title: 'Tyre Insurance',
    origin: 'Tyre',
    shortOrigin: 'tyre',
    image: 'Tyreimg'

  },
  {
    title: 'Cosmetic Insurance',
    origin: 'Cosmetic Repair',
    shortOrigin: 'cosmetic',
    image: 'CosmeticImg'

  },
  {
    title: 'Gap Insurance',
    origin: 'Gap',
    shortOrigin: 'gap',
    image: 'GapImg',


  },
  {
    title: 'Vehicle Warranty',
    origin: 'MBI',
    shortOrigin: 'mbi',
    image: 'MechanicalImg'

  },
  {
    title: 'Excess Insurance',
    origin: 'Excess',
    shortOrigin: 'excess',
    image: 'CosmeticImg'

  }
]

